body.new-design.marketwatch {
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select {
        border: 1px solid $chambray;
        color: $mine-shaft;
        //margin-bottom: 0;
        width: 100%;
    
        &:hover {
        }

        &:focus {
            //border-color: $chambray;
        }

        &::placeholder {
            color: rgba(58,76,147,0.5);
        }

    }

    input[type="checkbox"] ~ label {
        font-weight: 400;
    }

    .phone-field {
            input {
                width: 12%;

            &:last-of-type {
                width: 86%;
            }
        }
    }
}


@include bp(md) {
    body.new-design.marketwatch {
            .phone-field {
            input {
                width: 18%;

                &:last-of-type {
                    width: 80%;
                }
            }
        }
    }
}

@include bp(lg) {
    body.new-design.marketwatch {
        .phone-field {
            input {
                width: 12%;

                &:last-of-type {
                    width: 86%;
                }
            }
        }
    }
}

