@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@media print {
  .print-no-display {
    display: none !important;
  }

  body {
    padding: 0;
  }
  body header#top-bar,
body .ad,
body .button-container,
body .button-container.section-top,
body .subsection-nav-wrapper,
body button,
body a.button,
body a.btn,
body form#crop_iq_reports,
body footer.site-footer {
    display: none !important;
  }

  .toggle-content {
    display: block !important;
  }

  body.logged-in {
    padding: 0 !important;
  }

  .logged-in main {
    margin: 0 auto !important;
    max-width: none;
    padding: 0;
    width: auto;
  }
  .logged-in main.print {
    margin: bsu(0.5) auto;
  }

  .site-grid {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-items: flex-start;
    list-style: none;
    margin: 0;
  }
  .site-grid li {
    width: 23.5%;
  }
  .site-grid li:nth-child(3n+3) {
    margin: 0 2% bsu(1) 0;
  }
  .site-grid li:nth-child(4n+4) {
    margin: 0 0 bsu(1) 0;
  }

  .two-columns {
    margin-bottom: 0;
  }

  .two-columns {
    align-items: top;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: center;
  }
  .two-columns .column {
    margin: 0 2% 0 0;
    width: 49%;
  }
  .two-columns .column:nth-of-type(2n+2) {
    margin: 0;
  }

  .column.sidebar {
    margin: 0 3% 0 0;
    width: 67%;
  }
  .column.sidebar:nth-of-type(2n+2) {
    margin-bottom: bsu(1);
    width: 30%;
  }

  .two-column-map-info {
    margin: 0 3% 0 0;
    width: 30%;
  }

  .two-column-map-map {
    width: 67%;
  }

  .three-columns {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-end;
  }
  .three-columns section {
    margin: 0 2% bsu(1) 0;
    width: 32%;
  }
  .three-columns section:nth-of-type(3n+3) {
    margin: 0 0 bsu(1) 0;
  }

  .six-columns {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: top;
    justify-content: center;
  }
  .six-columns section {
    margin: 0 2% bsu(1) 0;
    width: 15%;
  }
  .six-columns section:nth-of-type(2n+2) {
    margin: 0 2% bsu(1) 0;
  }
  .six-columns section:nth-of-type(6n+6) {
    margin: 0 0 bsu(1) 0;
  }
  .six-columns.weather-conditions {
    page-break-after: always;
  }
  .six-columns.weather-conditions section {
    height: 100px;
    margin: 0 2% 0 0;
    width: 15%;
  }
  .six-columns.weather-conditions section:last-of-type {
    height: 100px;
    margin: 0;
    width: 15%;
  }

  .form-flex p {
    padding: bsu(0.4) 0 bsu(0.55) 0;
  }

  .form-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-end;
  }
  .form-flex .form-item {
    display: inline-block;
    margin: 0 2% 0 0;
    width: 49%;
  }
  .form-flex .form-item:nth-of-type(2n+2) {
    margin: 0 0 0 0;
  }

  .form-flex.three-column {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-end;
  }
  .form-flex.three-column .form-item {
    display: inline-block;
    margin: 0 2% 0 0;
    width: 32%;
  }
  .form-flex.three-column .form-item:nth-of-type(3n+3) {
    margin: 0 0 0 0;
  }

  .form-flex.four-column {
    display: -webkit-flex;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-end;
  }
  .form-flex.four-column .form-item {
    display: inline-block;
    margin: 0 2% 0 0;
    width: 23.5%;
  }
  .form-flex.four-column .form-item:nth-of-type(2n+2) {
    margin: 0 2% 0 0;
  }
  .form-flex.four-column .form-item:nth-of-type(4n+4) {
    margin: 0 0 0 0;
  }

  .form-flex.five-column {
    align-content: flex-end;
    display: -webkit-flex;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
  }
  .form-flex.five-column .form-item {
    display: inline-block;
    margin: 0 2% 0 0;
    width: 18.4%;
  }
  .form-flex.five-column .form-item:nth-of-type(2n+2) {
    margin: 0 2% 0 0;
  }
  .form-flex.five-column .form-item:nth-of-type(4n+4) {
    margin: 0 2% 0 0;
  }
  .form-flex.five-column .form-item:nth-of-type(5n+5) {
    margin: 0 0 0 0;
  }

  table,
table.non-responsive {
    display: table !important;
    margin: 0 0 20px 0 !important;
  }
  table thead,
table.non-responsive thead {
    line-height: 1.2;
    vertical-align: top;
    clip: none !important;
    height: auto !important;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto !important;
  }
  table tr,
table.non-responsive tr {
    border: none !important;
    border-bottom: 1px solid #dddfe1 !important;
    display: table-row !important;
  }
  table td,
table th,
table.non-responsive td,
table.non-responsive th {
    display: table-cell !important;
    padding: 10px !important;
    text-align: left !important;
  }
  table td:before,
table th:before,
table.non-responsive td:before,
table.non-responsive th:before {
    display: none;
  }

  tr td {
    background: #f2f2f2;
  }
  tr td:nth-child(2n+2) {
    background: #f2f2f2;
  }
  tr td input,
tr td select {
    float: none;
    width: 100%;
  }
  tr:nth-child(2n+2) td {
    background: #fff;
  }
  tr:nth-child(2n+2) td:nth-child(2n+2) {
    background: #fff;
  }

  .checkbox-container .styled-radio + label:before,
.checkbox-container .styled-checkbox + label:before,
.two-column-right.weather .styled-radio + label:before,
.two-column-right.weather .styled-checkbox + label:before {
    padding: 2px 0 0 2px;
    top: -1px;
  }

  .two-column-right.weather .styled-checkbox + label:before {
    padding: 0 0 0 2px;
    top: 2px;
  }

  #cropiq_application_information_all .time-date label,
#cropiq_application_information_farm .time-date label,
#cropiq_application_information_block .time-date label {
    margin: 0 2% 0 0;
    padding-top: 8px;
    text-align: right;
    width: 14%;
  }
  #cropiq_application_information_all .time-date input.datepicker,
#cropiq_application_information_farm .time-date input.datepicker,
#cropiq_application_information_block .time-date input.datepicker {
    width: 46%;
  }
  #cropiq_application_information_all .time-date input.hour, #cropiq_application_information_all .time-date input.minutes,
#cropiq_application_information_farm .time-date input.hour,
#cropiq_application_information_farm .time-date input.minutes,
#cropiq_application_information_block .time-date input.hour,
#cropiq_application_information_block .time-date input.minutes {
    width: 10%;
  }
  #cropiq_application_information_all .time-date select.am-pm,
#cropiq_application_information_farm .time-date select.am-pm,
#cropiq_application_information_block .time-date select.am-pm {
    width: 12%;
  }
  #cropiq_application_information_all .time-date p.hours,
#cropiq_application_information_farm .time-date p.hours,
#cropiq_application_information_block .time-date p.hours {
    font-size: 12px;
    margin: 0;
    padding-top: 8px;
    width: 62%;
  }

  table.information-blocks td:nth-child(3), table.information-blocks td:nth-child(4) {
    overflow: hidden;
  }
  table.information-blocks td:nth-child(3) input,
table.information-blocks td:nth-child(3) select, table.information-blocks td:nth-child(4) input,
table.information-blocks td:nth-child(4) select {
    float: left;
    margin: 0 2% 0 0;
    width: 28%;
  }
  table.information-blocks td:nth-child(3) select, table.information-blocks td:nth-child(4) select {
    margin: 0;
    width: 40%;
  }

  table.favorites th,
table.favorites td {
    width: 55%;
  }
  table.favorites th:first-of-type,
table.favorites td:first-of-type {
    width: 35%;
  }
  table.favorites th:last-of-type,
table.favorites td:last-of-type {
    width: 10%;
  }

  h1.current-application-header {
    padding: 0;
  }

  .product-calculations {
    text-align: right;
  }
  .product-calculations h3 {
    display: inline-block;
    margin: bsu(0.25) bsu(3) bsu(1) 0;
  }
  .product-calculations h3:last-of-type {
    margin: 0 0 bsu(1) 0;
  }

  #cropiq_application_products #tabs .form-flex .form-item {
    width: 23.5%;
  }
  #cropiq_application_products #tabs .form-flex .form-item:first-of-type, #cropiq_application_products #tabs .form-flex .form-item:nth-of-type(2), #cropiq_application_products #tabs .form-flex .form-item:nth-of-type(3), #cropiq_application_products #tabs .form-flex .form-item:nth-of-type(6), #cropiq_application_products #tabs .form-flex .form-item:nth-of-type(7), #cropiq_application_products #tabs .form-flex .form-item:nth-of-type(8) {
    width: 15%;
  }

  .current-application tr {
    border: none;
    margin: 0;
  }
  .current-application tr td {
    background: #f2f2f2;
    color: #404041;
    overflow: visible;
  }
  .current-application tr td:first-of-type {
    display: table-cell;
  }
  .current-application tr td:nth-child(2) {
    background: #f2f2f2;
    color: #404041;
  }
  .current-application tr:nth-child(2n+2) td {
    background: #fff;
  }
  .current-application tr:nth-child(2n+2) td:nth-child(2) {
    background: #fff;
  }
  .current-application th,
.current-application td {
    width: 14.75%;
  }
  .current-application th:first-of-type, .current-application th:nth-of-type(6), .current-application th:nth-of-type(7), .current-application th:nth-of-type(10),
.current-application td:first-of-type,
.current-application td:nth-of-type(6),
.current-application td:nth-of-type(7),
.current-application td:nth-of-type(10) {
    text-align: center;
    width: 6%;
  }
  .current-application th:nth-of-type(2),
.current-application td:nth-of-type(2) {
    width: 10%;
  }
  .current-application th:nth-of-type(9),
.current-application td:nth-of-type(9) {
    width: 7%;
  }

  .two-column-right.weather .form-item:last-of-type {
    border-bottom: 2px solid #bfcf26;
    margin-bottom: bsu(1);
    padding-bottom: bsu(1);
  }

  #records_filter .form-flex.five-column .form-item {
    margin: 0 2% 0 0;
    width: 18.4%;
  }
  #records_filter .form-flex.five-column .form-item:nth-child(2n+2) {
    margin: 0 2% 0 0;
  }
  #records_filter .form-flex.five-column .form-item:nth-child(3) {
    margin: 0 2% 0 0;
    width: 18.4%;
  }
  #records_filter .form-flex.five-column .form-item:nth-child(3) select {
    width: 100%;
  }
  #records_filter .form-flex.five-column .form-item:nth-child(4) {
    margin: 0 2% 0 0;
  }

  .report-summary {
    page-break-before: always;
  }
  .report-summary .row {
    page-break-after: always;
  }

  .two-columns.records .c-map-container {
    height: 100%;
  }

  td.see-by-block p {
    float: left;
    margin: 0 bsu(0.75) 0 0;
    width: auto;
  }
  td.see-by-block .styled-checkbox + label,
td.see-by-block .styled-radio + label {
    margin: 0 bsu(0.5) 0 0;
    padding: 0 0 0 bsu(1.5);
    width: auto;
  }
  td.see-by-block .styled-checkbox + label:last-of-type,
td.see-by-block .styled-radio + label:last-of-type {
    margin: 0;
  }

  .two-columns.records,
.products-table,
.products-table table.green-header,
.ppe.three-columns,
.weather-conditions.six-columns,
.work-order-print {
    margin-bottom: 5px;
  }
  .two-columns.records h1,
.products-table h1,
.products-table table.green-header h1,
.ppe.three-columns h1,
.weather-conditions.six-columns h1,
.work-order-print h1 {
    font-size: 14px;
    margin-bottom: 5px !important;
    padding: 0 !important;
  }
  .two-columns.records h4,
.products-table h4,
.products-table table.green-header h4,
.ppe.three-columns h4,
.weather-conditions.six-columns h4,
.work-order-print h4 {
    font-size: 12px;
  }
  .two-columns.records th,
.two-columns.records p,
.products-table th,
.products-table p,
.products-table table.green-header th,
.products-table table.green-header p,
.ppe.three-columns th,
.ppe.three-columns p,
.weather-conditions.six-columns th,
.weather-conditions.six-columns p,
.work-order-print th,
.work-order-print p {
    font-size: 12px !important;
  }
  .two-columns.records table tr th,
.two-columns.records table tr td,
.two-columns.records table.non-responsive tr th,
.two-columns.records table.non-responsive tr td,
.two-columns.records table.green-header tr th,
.two-columns.records table.green-header tr td,
.products-table table tr th,
.products-table table tr td,
.products-table table.non-responsive tr th,
.products-table table.non-responsive tr td,
.products-table table.green-header tr th,
.products-table table.green-header tr td,
.products-table table.green-header table tr th,
.products-table table.green-header table tr td,
.products-table table.green-header table.non-responsive tr th,
.products-table table.green-header table.non-responsive tr td,
.products-table table.green-header table.green-header tr th,
.products-table table.green-header table.green-header tr td,
.ppe.three-columns table tr th,
.ppe.three-columns table tr td,
.ppe.three-columns table.non-responsive tr th,
.ppe.three-columns table.non-responsive tr td,
.ppe.three-columns table.green-header tr th,
.ppe.three-columns table.green-header tr td,
.weather-conditions.six-columns table tr th,
.weather-conditions.six-columns table tr td,
.weather-conditions.six-columns table.non-responsive tr th,
.weather-conditions.six-columns table.non-responsive tr td,
.weather-conditions.six-columns table.green-header tr th,
.weather-conditions.six-columns table.green-header tr td,
.work-order-print table tr th,
.work-order-print table tr td,
.work-order-print table.non-responsive tr th,
.work-order-print table.non-responsive tr td,
.work-order-print table.green-header tr th,
.work-order-print table.green-header tr td {
    font-size: 12px !important;
    padding: 2px 5px 2px 0 !important;
  }

  .products-table table.green-header thead {
    background: #bfcf26;
  }
  .products-table table.green-header thead th:nth-of-type(8), .products-table table.green-header thead th:nth-of-type(9) {
    text-align: center;
  }
  .products-table table.green-header thead th:last-of-type, .products-table table.green-header thead th:nth-last-of-type(2) {
    display: none !important;
  }
  .products-table table.green-header tr {
    border-top: none;
    margin: 0;
  }
  .products-table table.green-header tr td:nth-child(2n+2) {
    background: #f2f2f2;
  }
  .products-table table.green-header tr td:nth-of-type(8), .products-table table.green-header tr td:nth-of-type(9) {
    text-align: center;
  }
  .products-table table.green-header tr td:last-of-type, .products-table table.green-header tr td:nth-last-of-type(2) {
    display: none !important;
  }
  .products-table table.green-header tr:nth-child(2n+2) td {
    background: transparent;
  }
  .products-table table.green-header tr:nth-child(2n+2) td:nth-child(2n+2) {
    background: transparent;
  }

  .ppe.three-columns {
    border-bottom: none;
    margin-bottom: 5px;
  }
  .ppe.three-columns section {
    margin: 0;
    padding: 0 10px 0 0;
  }

  .report-preview table thead,
.report-print table thead {
    background: #bfcf26;
    color: #fff;
  }
  .report-preview table th,
.report-preview table td,
.report-print table th,
.report-print table td {
    padding: 10px;
  }
  .report-preview table tr,
.report-print table tr {
    margin: 0;
  }
  .report-preview table tr th,
.report-print table tr th {
    background: #bfcf26;
    color: #fff;
  }
  .report-preview table tr td:first-of-type,
.report-print table tr td:first-of-type {
    background: #f2f2f2;
    color: #212626;
  }
  .report-preview table tr:nth-of-type(2n+2) td:first-of-type,
.report-print table tr:nth-of-type(2n+2) td:first-of-type {
    background: transparent;
  }
  .report-preview .total-cost,
.report-print .total-cost {
    overflow: hidden;
    text-align: right;
  }
  .report-preview .total-cost h3,
.report-print .total-cost h3 {
    float: right;
    padding: 0 0 0 bsu(0.5);
    width: 22.222%;
  }
  .report-preview .total-cost h3 span,
.report-print .total-cost h3 span {
    display: inline-block;
    margin: 0;
    width: 50%;
  }

  .report-preview table th,
.report-preview table td {
    width: 11.111%;
  }

  .report-print table {
    border-bottom: 2px solid #dddfe1 !important;
  }
  .report-print table tr th,
.report-print table tr td {
    font-size: 8pt !important;
    padding: 2px 5px 2px 0 !important;
    width: 8%;
  }
  .report-print table tr th:first-of-type,
.report-print table tr td:first-of-type {
    height: auto;
  }
  .report-print table tr th:nth-of-type(4), .report-print table tr th:nth-of-type(6),
.report-print table tr td:nth-of-type(4),
.report-print table tr td:nth-of-type(6) {
    width: 10%;
  }
  .report-print table tr th:nth-of-type(5), .report-print table tr th:nth-of-type(7),
.report-print table tr td:nth-of-type(5),
.report-print table tr td:nth-of-type(7) {
    width: 12%;
  }
  .report-print .total-cost h3 {
    padding: 0 0 0 bsu(0.5);
    text-align: right;
    width: 100%;
  }
  .report-print .total-cost h3 span {
    margin: 0 30px 0 0;
  }

  .tools {
    margin: 0 8% 0 0;
    width: 46%;
  }

  .tools-inner-wrapper .tool-container a {
    padding: 0 10%;
  }
  .tools-inner-wrapper .tool-container a svg {
    margin: bsu(1) 30% bsu(0.5) 30%;
  }
  .tools-inner-wrapper .tool-container a h2 {
    font-size: 18px;
  }

  .widgets {
    width: 46%;
  }

  .step-slider-widget .slide-inner-wrapper h2 {
    font-size: 18px;
  }

  .season-site-info {
    max-width: 50%;
  }

  #season_setup-map-view th {
    width: 21%;
  }
  #season_setup-map-view th:first-of-type {
    width: 32%;
  }
  #season_setup-map-view th:last-of-type {
    width: 5%;
  }

  .choose-farm h2.check {
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .planting-blocks,
.planting-split-blocks {
    table-layout: auto;
  }
  .planting-blocks tr:nth-child(2n+2) td:first-of-type,
.planting-split-blocks tr:nth-child(2n+2) td:first-of-type {
    background: #fff;
  }
  .planting-blocks td:first-of-type,
.planting-split-blocks td:first-of-type {
    background: #f2f2f2;
    color: #404041;
    padding-right: 40px;
    text-align: right;
    width: 12%;
  }
  .planting-blocks td:nth-of-type(7), .planting-blocks td:nth-of-type(8),
.planting-split-blocks td:nth-of-type(7),
.planting-split-blocks td:nth-of-type(8) {
    text-align: right;
  }

  .planting-blocks {
    margin: bsu(1) 0 0 0;
  }
  .planting-blocks tr {
    border-top: none;
    padding: 0;
  }

  .planting-split-blocks {
    margin: 0 0 bsu(3) 0;
    width: 100%;
  }
  .planting-split-blocks:before {
    display: block;
  }
  .planting-split-blocks tr:before, .planting-split-blocks tr:after {
    display: none;
  }
  .planting-split-blocks tr td {
    background: #fff;
  }
  .planting-split-blocks tr:nth-child(2n+2) td {
    background: #f2f2f2;
  }
  .planting-split-blocks td:first-of-type {
    background: #fff;
    position: relative;
  }
  .planting-split-blocks td:first-of-type:before {
    background: #bfcf26;
    content: "";
    display: block;
    height: 2px;
    left: 86px;
    margin-top: 10px;
    position: absolute;
    top: inherit;
    width: 20px;
    z-index: 1;
  }

  table.subsite.print {
    display: table;
  }

  #site_listing h2,
#site_subsite_info h2 {
    background: transparent;
    color: #404041;
    padding: bsu(1) bsu(0.5) bsu(0.35) bsu(0.5);
  }
  #site_listing table.original-site td:nth-child(2n+2),
#site_subsite_info table.original-site td:nth-child(2n+2) {
    background: #dddfe1;
  }
  #site_listing td:nth-of-type(1), #site_listing td:nth-of-type(2), #site_listing th:nth-of-type(1), #site_listing th:nth-of-type(2),
#site_subsite_info td:nth-of-type(1),
#site_subsite_info td:nth-of-type(2),
#site_subsite_info th:nth-of-type(1),
#site_subsite_info th:nth-of-type(2) {
    width: 30%;
  }
  #site_listing td:nth-of-type(3), #site_listing th:nth-of-type(3),
#site_subsite_info td:nth-of-type(3),
#site_subsite_info th:nth-of-type(3) {
    width: 25%;
  }
  #site_listing td:nth-of-type(4), #site_listing td:nth-of-type(5), #site_listing td:nth-of-type(6), #site_listing th:nth-of-type(4), #site_listing th:nth-of-type(5), #site_listing th:nth-of-type(6),
#site_subsite_info td:nth-of-type(4),
#site_subsite_info td:nth-of-type(5),
#site_subsite_info td:nth-of-type(6),
#site_subsite_info th:nth-of-type(4),
#site_subsite_info th:nth-of-type(5),
#site_subsite_info th:nth-of-type(6) {
    text-align: center;
    width: 5%;
  }
  #site_listing table.original-site th,
#site_subsite_info table.original-site th {
    background: #bfcf26;
    color: #fff;
  }
  #site_listing table.original-site tr td,
#site_subsite_info table.original-site tr td {
    background: #dddfe1;
  }
  #site_listing table.subsite,
#site_subsite_info table.subsite {
    margin-left: 16%;
    width: 84%;
  }
  #site_listing table.subsite th:nth-of-type(1),
#site_listing table.subsite td:nth-of-type(1),
#site_subsite_info table.subsite th:nth-of-type(1),
#site_subsite_info table.subsite td:nth-of-type(1) {
    width: 16.75%;
  }
  #site_listing table.subsite th:nth-of-type(2),
#site_listing table.subsite td:nth-of-type(2),
#site_subsite_info table.subsite th:nth-of-type(2),
#site_subsite_info table.subsite td:nth-of-type(2) {
    width: 35.75%;
  }
  #site_listing table.subsite th:nth-of-type(3),
#site_listing table.subsite td:nth-of-type(3),
#site_subsite_info table.subsite th:nth-of-type(3),
#site_subsite_info table.subsite td:nth-of-type(3) {
    width: 47.5%;
  }
  #site_listing table.subsite:before,
#site_subsite_info table.subsite:before {
    background: #bfcf26;
    bottom: 12px;
    content: "";
    display: block;
    height: 103%;
    left: -10%;
    position: absolute;
    width: 2px;
    z-index: 1;
  }
  #site_listing table.subsite tr,
#site_subsite_info table.subsite tr {
    padding-bottom: 0;
  }
  #site_listing table.subsite tr:before,
#site_subsite_info table.subsite tr:before {
    left: -10%;
    margin-top: 16px;
    top: inherit;
  }
  #site_listing table.subsite tr:after,
#site_subsite_info table.subsite tr:after {
    display: none;
  }
  #site_listing table.subsite tr td:first-of-type,
#site_subsite_info table.subsite tr td:first-of-type {
    background: #f2f2f2;
    color: #818285;
  }
  #site_listing table.subsite tr:nth-child(2n+2) td:first-of-type,
#site_subsite_info table.subsite tr:nth-child(2n+2) td:first-of-type {
    background: #fff;
  }

  .subsites-toggle .u-icon-accordion:before {
    right: 20px;
    top: 7px;
  }

  .draw-map h2:before {
    top: 0;
  }

  #site_setup {
    padding: bsu(0.75) 0 bsu(0.5) 0;
  }
  #site_setup .form-flex.three-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
  }
  #site_setup .form-flex.three-column .form-item {
    display: inline-block;
    margin: 0 1% 0 0;
    width: 18%;
  }
  #site_setup .form-flex.three-column .form-item#site_name, #site_setup .form-flex.three-column .form-item#site_address {
    width: 30%;
  }
  #site_setup .form-flex.three-column .form-item#site_state {
    margin: 0 1% 0 0;
  }
  #site_setup .form-flex.three-column .form-item#site_zip {
    margin: 0 1% 0 0;
    width: 14%;
  }

  #site_subsite_info table.subsite:before {
    background: #bfcf26;
    bottom: 22px;
    content: "";
    display: block;
    height: 102%;
    left: -15%;
    position: absolute;
    width: 2px;
    z-index: 1;
  }
  #site_subsite_info table.subsite tr {
    padding-bottom: 0;
  }
  #site_subsite_info table.subsite tr:before {
    left: -15%;
    margin-top: 16px;
    top: inherit;
  }
  #site_subsite_info table.subsite tr:after {
    display: none;
  }
  #site_subsite_info table.subsite tr td:first-of-type {
    background: #f2f2f2;
    color: #818285;
  }
  #site_subsite_info table.subsite tr:nth-child(2n+2) td:first-of-type {
    background: #fff;
  }
  #site_subsite_info td, #site_subsite_info th {
    width: 19.333%;
  }
  #site_subsite_info td:nth-of-type(2), #site_subsite_info td:nth-of-type(3), #site_subsite_info th:nth-of-type(2), #site_subsite_info th:nth-of-type(3) {
    width: 15%;
  }
  #site_subsite_info td:nth-of-type(6), #site_subsite_info td:nth-of-type(7), #site_subsite_info td:nth-of-type(8), #site_subsite_info th:nth-of-type(6), #site_subsite_info th:nth-of-type(7), #site_subsite_info th:nth-of-type(8) {
    text-align: center;
    width: 4%;
  }
  #site_subsite_info table.subsite {
    margin-left: 19.5%;
    width: 80.5%;
  }
  #site_subsite_info table.subsite th,
#site_subsite_info table.subsite td {
    width: 24.5%;
  }
  #site_subsite_info table.subsite th:first-of-type,
#site_subsite_info table.subsite td:first-of-type {
    width: 38%;
  }
  #site_subsite_info table.subsite th:nth-of-type(4), #site_subsite_info table.subsite th:nth-of-type(5), #site_subsite_info table.subsite th:nth-of-type(6),
#site_subsite_info table.subsite td:nth-of-type(4),
#site_subsite_info table.subsite td:nth-of-type(5),
#site_subsite_info table.subsite td:nth-of-type(6) {
    text-align: center;
    width: 5%;
  }

  main figure {
    padding: 40px 50px 0 50px;
  }
  main .not-logged-in-top {
    padding: 10px 50px 0 50px;
  }

  [data-view=Dashboard] main {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: top;
    justify-content: center;
  }

  main.dashboard {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: top;
    justify-content: center;
  }

  #lab_results .form-item {
    width: 49%;
  }
  #lab_results .form-item.start-date, #lab_results .form-item.end-date {
    margin: 0 2% 0 0;
    width: 49%;
  }
  #lab_results .form-item.end-date {
    margin: 0;
  }
  #lab_results .form-item button {
    margin-top: 24px;
  }

  .results-table .report-details {
    margin: 0 bsu(0.25);
  }
  .results-table .report-details ul {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-start;
    vertical-align: top;
    list-style: none;
    margin: 0;
    list-style: none;
    margin: 0;
  }
  .results-table .report-details ul li {
    margin-bottom: 2px;
    width: 50%;
  }

  #lab_results .form-item {
    width: 23.5%;
  }
  #lab_results .form-item.start-date, #lab_results .form-item.end-date {
    margin: 0 2% 0 0;
    width: 23.5%;
  }
  #lab_results .form-item.end-date, #lab_results .form-item:nth-child(2n+2) {
    margin: 0 2% 0 0;
  }
  #lab_results .form-item:nth-child(4n+4) {
    margin: 0;
  }

  .results-table h1:after {
    display: none;
  }
  .results-table .row {
    margin-bottom: 0;
  }
  .results-table .row tr {
    background: transparent;
  }
  .results-table .row:nth-child(2n+2) tr {
    background: #f2f2f2;
  }
  .results-table table.results-table-header,
.results-table table.results-row {
    border: none;
  }
  .results-table table.results-table-header th,
.results-table table.results-table-header td,
.results-table table.results-row th,
.results-table table.results-row td {
    width: 15%;
  }
  .results-table table.results-table-header th:first-of-type,
.results-table table.results-table-header td:first-of-type,
.results-table table.results-row th:first-of-type,
.results-table table.results-row td:first-of-type {
    padding-left: bsu(2);
    width: 20%;
  }
  .results-table table.results-table-header th:last-of-type,
.results-table table.results-table-header td:last-of-type,
.results-table table.results-row th:last-of-type,
.results-table table.results-row td:last-of-type {
    text-align: center;
    width: 5%;
  }
  .results-table table.results-row td {
    background: transparent;
  }
  .results-table table.results-row td:first-of-type {
    background: transparent;
    color: #404041;
  }
  .results-table .report-details {
    box-shadow: none;
    padding: 1px 1px 0 1px;
  }
  .results-table .report-details ul {
    list-style: none;
    margin: 0;
  }
  .results-table .report-details ul li {
    margin-bottom: 1px;
    width: 25%;
  }

  #scouting_create_new .form-flex.two-columns {
    max-width: 40%;
  }
  #scouting_create_new table.create-scouting-report-table tr td,
#scouting_create_new table.create-scouting-report-table tr th,
#scouting_create_new table.create-scouting-report-table thead td,
#scouting_create_new table.create-scouting-report-table thead th {
    vertical-align: top;
    width: auto;
  }
  #scouting_create_new table.create-scouting-report-table tr td:nth-of-type(2),
#scouting_create_new table.create-scouting-report-table tr th:nth-of-type(2),
#scouting_create_new table.create-scouting-report-table thead td:nth-of-type(2),
#scouting_create_new table.create-scouting-report-table thead th:nth-of-type(2) {
    width: 35%;
  }
  #scouting_create_new table.create-scouting-report-table tr td:nth-last-of-type(2),
#scouting_create_new table.create-scouting-report-table tr th:nth-last-of-type(2),
#scouting_create_new table.create-scouting-report-table thead td:nth-last-of-type(2),
#scouting_create_new table.create-scouting-report-table thead th:nth-last-of-type(2) {
    width: 12%;
  }
  #scouting_create_new table.create-scouting-report-table tr td:first-of-type,
#scouting_create_new table.create-scouting-report-table tr th:first-of-type,
#scouting_create_new table.create-scouting-report-table thead td:first-of-type,
#scouting_create_new table.create-scouting-report-table thead th:first-of-type {
    width: 8%;
  }
  #scouting_create_new table.create-scouting-report-table tr td:last-of-type,
#scouting_create_new table.create-scouting-report-table tr th:last-of-type,
#scouting_create_new table.create-scouting-report-table thead td:last-of-type,
#scouting_create_new table.create-scouting-report-table thead th:last-of-type {
    text-align: center;
    width: 5%;
  }
  #scouting_create_new table.create-scouting-report-table tr td input,
#scouting_create_new table.create-scouting-report-table tr td textarea,
#scouting_create_new table.create-scouting-report-table tr td p,
#scouting_create_new table.create-scouting-report-table tr th input,
#scouting_create_new table.create-scouting-report-table tr th textarea,
#scouting_create_new table.create-scouting-report-table tr th p,
#scouting_create_new table.create-scouting-report-table thead td input,
#scouting_create_new table.create-scouting-report-table thead td textarea,
#scouting_create_new table.create-scouting-report-table thead td p,
#scouting_create_new table.create-scouting-report-table thead th input,
#scouting_create_new table.create-scouting-report-table thead th textarea,
#scouting_create_new table.create-scouting-report-table thead th p {
    float: none;
    width: 100%;
  }
  #scouting_create_new table.create-scouting-report-table tr td input,
#scouting_create_new table.create-scouting-report-table tr td select,
#scouting_create_new table.create-scouting-report-table tr th input,
#scouting_create_new table.create-scouting-report-table tr th select,
#scouting_create_new table.create-scouting-report-table thead td input,
#scouting_create_new table.create-scouting-report-table thead td select,
#scouting_create_new table.create-scouting-report-table thead th input,
#scouting_create_new table.create-scouting-report-table thead th select {
    margin: bsu(0.25) 0 0 0;
    width: 100%;
  }
  #scouting_create_new table.create-scouting-report-table tr {
    padding: 0;
  }

  .two-columns.create-report .column,
.two-columns.view-report .column {
    width: 40%;
  }
  .two-columns.create-report .column:nth-of-type(2n+2),
.two-columns.view-report .column:nth-of-type(2n+2) {
    width: 58%;
  }

  table.create-scouting-report-table tr td,
table.create-scouting-report-table tr th,
table.create-scouting-report-table thead td,
table.create-scouting-report-table thead th {
    vertical-align: top;
    width: auto;
  }
  table.create-scouting-report-table tr td:nth-of-type(2),
table.create-scouting-report-table tr th:nth-of-type(2),
table.create-scouting-report-table thead td:nth-of-type(2),
table.create-scouting-report-table thead th:nth-of-type(2) {
    width: 30% !important;
  }
  table.create-scouting-report-table tr td:first-of-type,
table.create-scouting-report-table tr th:first-of-type,
table.create-scouting-report-table thead td:first-of-type,
table.create-scouting-report-table thead th:first-of-type {
    width: 8% !important;
  }
  table.create-scouting-report-table tr td:last-of-type,
table.create-scouting-report-table tr th:last-of-type,
table.create-scouting-report-table thead td:last-of-type,
table.create-scouting-report-table thead th:last-of-type {
    text-align: center;
    width: 15% !important;
  }
  table.create-scouting-report-table tr td input,
table.create-scouting-report-table tr td textarea,
table.create-scouting-report-table tr td p,
table.create-scouting-report-table tr th input,
table.create-scouting-report-table tr th textarea,
table.create-scouting-report-table tr th p,
table.create-scouting-report-table thead td input,
table.create-scouting-report-table thead td textarea,
table.create-scouting-report-table thead td p,
table.create-scouting-report-table thead th input,
table.create-scouting-report-table thead th textarea,
table.create-scouting-report-table thead th p {
    float: none;
    width: 100%;
  }
  table.create-scouting-report-table tr td input,
table.create-scouting-report-table tr td select,
table.create-scouting-report-table tr th input,
table.create-scouting-report-table tr th select,
table.create-scouting-report-table thead td input,
table.create-scouting-report-table thead td select,
table.create-scouting-report-table thead th input,
table.create-scouting-report-table thead th select {
    margin: bsu(0.25) 0 0 0;
    width: 100%;
  }
  table.create-scouting-report-table tr td p,
table.create-scouting-report-table tr th p,
table.create-scouting-report-table thead td p,
table.create-scouting-report-table thead th p {
    margin: 0;
  }

  [data-view=scouting-view-reports] h1 {
    font-size: 14px;
    margin: 0 0 5px 0 !important;
    padding: 0 !important;
  }
  [data-view=scouting-view-reports] h1:after {
    display: none;
  }
  [data-view=scouting-view-reports] textarea {
    margin-bottom: 0 !important;
  }
  [data-view=scouting-view-reports] .two-columns.view-report {
    margin-bottom: 0 !important;
  }
  [data-view=scouting-view-reports] .two-columns.view-report .column {
    margin-bottom: 0 !important;
  }
  [data-view=scouting-view-reports] .two-columns.view-report table tr th,
[data-view=scouting-view-reports] .two-columns.view-report table tr td,
[data-view=scouting-view-reports] .two-columns.view-report table tr p {
    font-size: 12px !important;
  }
  [data-view=scouting-view-reports] .two-columns.view-report table tr th,
[data-view=scouting-view-reports] .two-columns.view-report table tr td {
    padding: 2px 5px 2px 0 !important;
  }
  [data-view=scouting-view-reports] table.green-header.create-scouting-report-table tr th,
[data-view=scouting-view-reports] table.green-header.create-scouting-report-table tr td {
    font-size: 12px !important;
    margin: 0 !important;
    padding: 2px 5px 2px 0 !important;
    width: auto !important;
  }
  [data-view=scouting-view-reports] table.green-header.create-scouting-report-table tr th p,
[data-view=scouting-view-reports] table.green-header.create-scouting-report-table tr td p {
    font-size: 12px !important;
    margin: 0 0 5px 0 !important;
    padding: 0 !important;
    width: 100%;
  }
  [data-view=scouting-view-reports] table.green-header.create-scouting-report-table tr th:first-of-type,
[data-view=scouting-view-reports] table.green-header.create-scouting-report-table tr td:first-of-type {
    width: 8% !important;
  }
  [data-view=scouting-view-reports] table.green-header.create-scouting-report-table tr th:last-of-type,
[data-view=scouting-view-reports] table.green-header.create-scouting-report-table tr td:last-of-type {
    display: none !important;
  }

  .scouting-records tr {
    padding: 0;
  }
  .scouting-records tr th {
    background: #bfcf26;
    color: #fff;
  }
  .scouting-records tr th,
.scouting-records tr td {
    padding: bsu(0.5);
    width: auto;
  }
  .scouting-records tr th:nth-of-type(3n+3),
.scouting-records tr td:nth-of-type(3n+3) {
    width: 30%;
  }
  .scouting-records tr th:nth-of-type(4n+4),
.scouting-records tr td:nth-of-type(4n+4) {
    text-align: center;
  }
  .scouting-records tr th:last-of-type,
.scouting-records tr td:last-of-type {
    text-align: center;
    width: 10%;
  }
  .scouting-records .styled-checkbox + label,
.scouting-records .styled-radio + label {
    margin: 0;
  }
  .scouting-records .styled-checkbox + label:before,
.scouting-records .styled-radio + label:before {
    left: 50%;
    margin: -10px 0 0 -10px;
  }
  .scouting-records .email-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-end;
    margin-left: 50%;
    width: 50%;
  }
  .scouting-records .email-container label {
    margin: 0 2% 0 0;
    width: 20%;
  }
  .scouting-records .email-container input {
    margin: 0 2% 0 0;
    width: 40%;
  }
  .scouting-records .email-container button {
    width: 36%;
  }

  .per p {
    margin-top: 29px;
    padding: 0;
    width: 100%;
  }
  .per p:before, .per p:after {
    display: none;
  }

  .input-cost-report {
    margin-top: bsu(1);
  }
  .input-cost-report .row p {
    width: 63%;
  }

  .cost-input-details.toggle-button {
    width: 2%;
  }

  #input_costs .five-column .form-item {
    margin: 0 2% 0 0;
    width: 22%;
  }
  #input_costs .five-column .form-item:nth-of-type(2n+2) {
    margin: 0 2% 0 0;
  }
  #input_costs .five-column .form-item:nth-of-type(4) {
    width: 4%;
  }
  #input_costs .five-column .form-item:last-of-type {
    margin: 0;
  }
  #input_costs button,
#input_costs .button {
    margin-top: 23px;
  }

  .input-cost-details table thead {
    border-bottom: 2px solid #fff;
    clip: 0;
    font-weight: 600;
    height: auto;
    margin: 0;
    position: relative;
    width: auto;
  }
  .input-cost-details table thead th {
    width: 25%;
  }
  .input-cost-details table thead th:nth-of-type(2) {
    width: 20%;
  }
  .input-cost-details table thead th:last-of-type {
    width: 30%;
  }
  .input-cost-details table tr {
    border: none;
    display: table-row;
  }
  .input-cost-details table tr td, .input-cost-details table tr th {
    display: table-cell;
    text-align: left;
  }
  .input-cost-details table tr th {
    padding: bsu(0.25) bsu(0.5) bsu(0.5) bsu(0.5);
  }
  .input-cost-details table td {
    background: transparent;
    border-radius: 0;
    padding: bsu(0.25) bsu(0.5);
  }
  .input-cost-details table td:before {
    display: none;
  }

  .search-results .row,
.search-reults .row {
    margin-bottom: 0;
  }
  .search-results .row:nth-child(2n+2) tr td,
.search-reults .row:nth-child(2n+2) tr td {
    background: transparent;
  }
  .search-results table.search-reults-table th,
.search-results table.search-reults-table td,
.search-results table.search-results-table th,
.search-results table.search-results-table td,
.search-reults table.search-reults-table th,
.search-reults table.search-reults-table td,
.search-reults table.search-results-table th,
.search-reults table.search-results-table td {
    width: auto;
  }
  .search-results table.search-reults-table th:first-of-type,
.search-results table.search-reults-table td:first-of-type,
.search-results table.search-results-table th:first-of-type,
.search-results table.search-results-table td:first-of-type,
.search-reults table.search-reults-table th:first-of-type,
.search-reults table.search-reults-table td:first-of-type,
.search-reults table.search-results-table th:first-of-type,
.search-reults table.search-results-table td:first-of-type {
    padding-left: bsu(1.75);
  }
  .search-results table.search-reults-table th:last-of-type,
.search-results table.search-reults-table td:last-of-type,
.search-results table.search-results-table th:last-of-type,
.search-results table.search-results-table td:last-of-type,
.search-reults table.search-reults-table th:last-of-type,
.search-reults table.search-reults-table td:last-of-type,
.search-reults table.search-results-table th:last-of-type,
.search-reults table.search-results-table td:last-of-type {
    width: 40%;
  }
  .search-results table.search-reults-table td,
.search-results table.search-results-table td,
.search-reults table.search-reults-table td,
.search-reults table.search-results-table td {
    padding: 10px bsu(0.5);
  }
  .search-results table.search-reults-table td:first-of-type,
.search-results table.search-results-table td:first-of-type,
.search-reults table.search-reults-table td:first-of-type,
.search-reults table.search-results-table td:first-of-type {
    padding: 10px bsu(0.5) 10px bsu(1.75);
  }

  .view-labels.toggle-button {
    background: transparent;
    color: #404041;
    left: 0;
    padding: 0;
    position: absolute;
    top: 5px;
  }
  .view-labels.toggle-button:hover {
    background: transparent;
    color: #404041;
  }
  .view-labels.toggle-button .u-icon-accordion:before {
    color: #404041;
    left: 10px;
    top: 8px;
  }
  .view-labels.toggle-button.open .u-icon-accordion:before {
    color: #9aab00;
  }
  .view-labels.toggle-button label {
    visibility: hidden !important;
    transform: scale(0) !important;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .labels {
    margin: 0 bsu(0.25);
    padding: bsu(1) bsu(2);
  }

  .log-info.form-flex.two-column-mobile section p,
.log-info section p {
    margin-bottom: 5px !important;
  }

  .log-header-field {
    width: 20%;
  }

  table.log-question-table tr td {
    width: 50%;
  }
  table.log-question-table tr td:nth-child(2n+2) {
    width: 50%;
  }

  table.log-checkbox-table {
    margin: 0 0 10px 0 !important;
  }
  table.log-checkbox-table th,
table.log-checkbox-table td {
    text-align: center !important;
    width: 10%;
  }
  table.log-checkbox-table th:first-of-type,
table.log-checkbox-table td:first-of-type {
    text-align: left !important;
    width: 80%;
  }

  .e-sign {
    display: inherit;
    overflow: hidden;
  }
  .e-sign section {
    float: left;
    margin: bsu(2) 4% 0 0 !important;
    width: 48% !important;
  }
  .e-sign section:nth-of-type(2n+2), .e-sign section:last-of-type {
    margin: bsu(2) 0 0 0 !important;
  }
}
.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

body.new-design.marketwatch input[type=text],
body.new-design.marketwatch input[type=email],
body.new-design.marketwatch input[type=tel],
body.new-design.marketwatch select {
  border: 1px solid #3A4C93;
  color: #333333;
  width: 100%;
}
body.new-design.marketwatch input[type=text]::placeholder,
body.new-design.marketwatch input[type=email]::placeholder,
body.new-design.marketwatch input[type=tel]::placeholder,
body.new-design.marketwatch select::placeholder {
  color: rgba(58, 76, 147, 0.5);
}
body.new-design.marketwatch input[type=checkbox] ~ label {
  font-weight: 400;
}
body.new-design.marketwatch .phone-field input {
  width: 12%;
}
body.new-design.marketwatch .phone-field input:last-of-type {
  width: 86%;
}

@media all and (min-width: 56em) {
  body.new-design.marketwatch .phone-field input {
    width: 18%;
  }
  body.new-design.marketwatch .phone-field input:last-of-type {
    width: 80%;
  }
}
@media all and (min-width: 75em) {
  body.new-design.marketwatch .phone-field input {
    width: 12%;
  }
  body.new-design.marketwatch .phone-field input:last-of-type {
    width: 86%;
  }
}
body.new-design.marketwatch h3 {
  font-size: 18px;
}
body.new-design.marketwatch a {
  color: #3A4C93;
}
body.new-design.marketwatch .error {
  color: #b10009;
}
body.new-design.marketwatch .error a {
  color: #b10009;
  text-decoration: underline;
}

body.new-design.marketwatch main.login-page {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  margin: 0;
  max-width: 1260px;
  width: 100%;
}
body.new-design.marketwatch main.login-page .st0 {
  fill: #fff;
}

body.new-design.marketwatch {
  color: #212626;
}
body.new-design.marketwatch h1,
body.new-design.marketwatch h2,
body.new-design.marketwatch h3,
body.new-design.marketwatch h4,
body.new-design.marketwatch .ui-widget,
body.new-design.marketwatch label,
body.new-design.marketwatch input,
body.new-design.marketwatch select,
body.new-design.marketwatch textarea {
  color: #212626;
}
body.new-design.marketwatch main.login-page {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  margin: 0;
  max-width: 1266px;
  width: 100%;
}
body.new-design.marketwatch main.login-page .logo-fill {
  fill: #fff;
}

@media all and (min-width: 56em) {
  body.new-design.marketwatch .site-outer-wrapper {
    flex: inherit;
  }
}
@media all and (min-width: 56em) {
  body.new-design.marketwatch .site-outer-wrapper {
    margin-top: 60px;
  }
  body.new-design.marketwatch main.login-page {
    border-radius: 6px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);
    margin: 0 auto;
    min-height: 709px;
    overflow: hidden;
    width: 90%;
    align-items: stretch;
  }
  body.new-design.marketwatch main.login-page .logo-fill {
    fill: #3A4C93;
  }
}
body.new-design.marketwatch .slogan-container {
  background: url("../../Content/img/login-bg.jpg") no-repeat center center;
  background-size: cover;
  display: none;
  height: 100%;
  padding: 65px;
  width: 63%;
}
body.new-design.marketwatch .slogan-container h1,
body.new-design.marketwatch .slogan-container h3,
body.new-design.marketwatch .slogan-container p {
  color: #fff;
  max-width: 500px;
}
body.new-design.marketwatch .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 0 5px 0;
  width: 100%;
}
body.new-design.marketwatch .logo-container {
  background: url("../../Content/img/login-bg.jpg") no-repeat center center;
  background-size: 200%;
  margin: 0 auto;
  padding: 28px 0;
  text-align: center;
  width: 100%;
}
body.new-design.marketwatch .logo-container svg {
  width: 285px;
}
body.new-design.marketwatch .content-container {
  height: 100%;
  max-width: 400px;
  padding: 40px 15px 15px 15px;
  position: relative;
  width: 100%;
}

@media all and (min-width: 56em) {
  body.new-design.marketwatch .slogan-container {
    display: block;
  }
  body.new-design.marketwatch .content-wrapper {
    padding: 0 0 15px 0;
    width: 37%;
  }
  body.new-design.marketwatch .content-container {
    padding: 40px 30px 15px 30px;
  }
  body.new-design.marketwatch .logo-container {
    background: none;
  }
  body.new-design.marketwatch .logo-container svg {
    width: 285px;
  }
}
body.new-design.marketwatch .footer-container {
  font-size: 14px;
  padding: 0 15px;
  width: 100%;
}

@media all and (min-width: 56em) {
  body.new-design.marketwatch .footer-container {
    font-size: 16px;
  }
}
.footer-links {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  line-height: 1;
  list-style: none;
  margin: 0 35px 10px 35px;
}
.footer-links li {
  padding: 0 10px;
  position: relative;
}
.footer-links li:after {
  background: #333333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 1px;
}
.footer-links li:after {
  right: 0;
  top: 0;
}
.footer-links li:first-of-type {
  padding: 0 10px 0 0;
}
.footer-links li:nth-of-type(2) {
  padding: 0 0 0 10px;
}
.footer-links li:nth-of-type(2):after {
  display: none;
}
.footer-links li:last-of-type {
  padding: 0 0 0 10px;
}
.footer-links li:last-of-type:after {
  display: none;
}

@media all and (min-width: 56em) {
  .footer-links {
    margin: 0 5px 15px 5px;
  }
  .footer-links li {
    padding: 0 5px;
  }
  .footer-links li:first-of-type {
    padding: 0 5px 0 0;
  }
  .footer-links li:nth-of-type(2) {
    padding: 0 5px;
  }
  .footer-links li:nth-of-type(2)::after {
    display: block;
  }
  .footer-links li:last-of-type {
    padding: 0 0 0 5px;
  }
}
.footer-copyright {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.footer-copyright div {
  width: 100%;
}
.footer-copyright div:nth-of-type(2) {
  display: none;
}

@media all and (min-width: 56em) {
  .footer-copyright div {
    width: auto;
  }
  .footer-copyright div:nth-of-type(2) {
    display: initial;
    padding: 0 5px;
  }
}
body.new-design.marketwatch input.company-select {
  border: 1px solid #3A4C93;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  margin: 0;
}
body.new-design.marketwatch .company-list,
body.new-design.marketwatch .user-list {
  border: 1px solid #3A4C93;
  border-radius: 4px;
}
body.new-design.marketwatch .company-list ul.striped-list li,
body.new-design.marketwatch .user-list ul.striped-list li {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  min-height: 44px;
  padding: 0;
}
body.new-design.marketwatch .company-list ul.striped-list li:hover,
body.new-design.marketwatch .user-list ul.striped-list li:hover {
  background: #3A4C93;
}
body.new-design.marketwatch .company-list ul.striped-list li:hover a,
body.new-design.marketwatch .user-list ul.striped-list li:hover a {
  color: #fff;
  font-weight: 600;
}
body.new-design.marketwatch .company-list ul.striped-list li a,
body.new-design.marketwatch .user-list ul.striped-list li a {
  color: #212626;
  display: block;
  font-weight: 400;
  height: auto;
  min-height: 100%;
  padding: 0 15px;
  width: 100%;
}
body.new-design.marketwatch .company-list ul.striped-list li.active,
body.new-design.marketwatch .user-list ul.striped-list li.active {
  background: #3A4C93;
}
body.new-design.marketwatch .company-list ul.striped-list li.active a,
body.new-design.marketwatch .user-list ul.striped-list li.active a {
  color: #fff;
  font-weight: 600;
}
body.new-design.marketwatch .company-list {
  border-radius: 0 0 4px 4px;
}

body.new-design .dashboard section {
  width: 100%;
}
body.new-design .dashboard h1,
body.new-design .dashboard h2,
body.new-design .dashboard h3,
body.new-design .dashboard h4,
body.new-design .dashboard .ui-widget,
body.new-design .dashboard label,
body.new-design .dashboard p {
  color: #fff;
}
body.new-design .dashboard h1 {
  background: transparent;
  border-radius: 0;
  font-size: 30px;
  font-weight: 400;
  padding: 0;
}
body.new-design .dashboard label {
  padding-left: 0;
}
body.new-design .dashboard input,
body.new-design .dashboard input[type=tel],
body.new-design .dashboard select {
  background: rgba(135, 135, 135, 0.75);
  border: 1px solid #C8C8C8;
  border-radius: 3px;
  color: #fff;
  font-size: 18px;
  margin: 0;
  padding: 10px;
}
body.new-design .dashboard input:hover,
body.new-design .dashboard input[type=tel]:hover,
body.new-design .dashboard select:hover {
  border-color: #fff;
}
body.new-design .dashboard input:focus,
body.new-design .dashboard input[type=tel]:focus,
body.new-design .dashboard select:focus {
  border-color: #fff;
  box-shadow: none;
  outline: none;
}
body.new-design .dashboard input::placeholder,
body.new-design .dashboard input[type=tel]::placeholder,
body.new-design .dashboard select::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
body.new-design .dashboard select {
  background: rgba(135, 135, 135, 0.75) url("/shared-assets/Content/img/icons/icon_arrow_down_white.svg") no-repeat 98% center;
  background-size: 24px;
  color: #fff;
  width: 100%;
}
body.new-design .dashboard select.selected {
  color: #fff;
}
body.new-design .dashboard-welcome-container {
  padding: 40px 0;
}
body.new-design .dashboard-welcome-container section {
  margin: 0 auto;
  width: auto;
}
body.new-design .dashboard-welcome-container h1,
body.new-design .dashboard-welcome-container h2,
body.new-design .dashboard-welcome-container p {
  text-align: center;
}
body.new-design .dashboard-welcome-container h1 {
  width: 100%;
}
body.new-design .dashboard-welcome-container h2 {
  font-weight: bold;
  margin: 0 0 5px 0;
  padding: 0 0 5px 0;
  position: relative;
}
body.new-design .dashboard-welcome-container h2:after {
  background: #fff;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
body.new-design .dashboard-welcome-container p {
  font: 9px;
}

@media all and (min-width: 38em) {
  body.new-design .dashboard-welcome-container {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-flow: row wrap;
  }
  body.new-design .dashboard-welcome-container h1 {
    text-align: left;
    width: 60%;
  }
  body.new-design .dashboard-welcome-container section {
    width: 35%;
  }
}
@media all and (min-width: 56em) {
  body.new-design .dashboard-welcome-container h1 {
    width: 70%;
  }
  body.new-design .dashboard-welcome-container section {
    margin: 0;
    width: 25%;
  }
  body.new-design .dashboard input,
body.new-design .dashboard input[type=tel],
body.new-design .dashboard select {
    font-size: 20px;
  }
  body.new-design .dashboard .two-column {
    align-items: flex-start;
  }
  body.new-design .dashboard .two-column .left-column {
    width: 70%;
  }
  body.new-design .dashboard .two-column .right-column {
    margin-top: 27px;
    width: 25%;
  }
}
@media all and (min-width: 75em) {
  body.new-design .dashboard {
    position: relative;
  }
  body.new-design .dashboard main.full-width {
    margin: 100px 5% 40px 10%;
    max-width: 85%;
  }
}
body.new-design .dashboard-widget-right-column .widget-header h1 {
  font-size: 18px;
}

.dashboard-widget-right-column {
  background: rgba(135, 135, 135, 0.65);
  border-radius: 6px;
}
.dashboard-widget-right-column .widget-header {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: row wrap;
  border-bottom: 1px solid #fff;
  padding: 10px 20px;
}
.dashboard-widget-right-column .widget-header h1 {
  margin: 0;
  width: auto;
}
.dashboard-widget-right-column .widget-header .widget-menu {
  padding: 5px 0 0 0;
  width: auto;
}
.dashboard-widget-right-column .widget-header .widget-menu svg {
  fill: #fff;
}
.dashboard-widget-right-column .widget-content {
  padding: 20px;
}
.dashboard-widget-right-column .widget-content .btn {
  margin: 0;
  padding: 9px 20px 11px 20px;
  width: 100%;
}
.dashboard-widget-right-column .notifications-list {
  list-style: none;
  margin: 0 0 40px 0;
}
.dashboard-widget-right-column .notifications-list li {
  background: rgba(26, 40, 94, 0.2);
  color: #fff;
  font-size: 10px;
  margin: 0;
  padding: 10px;
}
.dashboard-widget-right-column .notifications-list li:nth-of-type(2n+2) {
  background: transparent;
}
.dashboard-widget-right-column .notifications-list li a {
  color: #fff;
}
.dashboard-widget-right-column .notifications-list li a:hover {
  color: #fff;
  text-decoration: underline;
}

.market-watch-dashboard {
  background: url("../../Content/img/dashboard-bg-marketwatch-min.jpg") no-repeat center center fixed;
  background-size: cover;
  display: block;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  width: 100%;
}
.market-watch-dashboard.strawberry {
  background: url("../../Content/img/dashboard-bg-marketwatch-strawberry-min.jpg") no-repeat center center fixed;
  background-size: cover;
}
.market-watch-dashboard.blueberry {
  background: url("../../Content/img/dashboard-bg-marketwatch-blueberry-min.jpg") no-repeat center center fixed;
  background-size: cover;
}
.market-watch-dashboard.peach {
  background: url("../../Content/img/dashboard-bg-marketwatch-peach-min.jpg") no-repeat center center fixed;
  background-size: cover;
}

.select-commodity,
.marketwatch-lite-container {
  margin: 0 0 50px 0;
}

.select-dashboard {
  margin: 0 0 20px 0;
}

.dashboard-tiles {
  align-content: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
}
.dashboard-tiles li {
  background: rgba(135, 135, 135, 0.65);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 0 4% 10px 0;
  padding: 25px 0 35px 0;
  position: relative;
  text-align: center;
  transition: all 0.7s ease;
  width: 48%;
}
.dashboard-tiles li:nth-of-type(2n+2), .dashboard-tiles li:last-of-type {
  margin: 0 0 10px 0;
}
.dashboard-tiles li:hover {
  cursor: pointer;
  background: rgba(135, 135, 135, 0.95);
}
.dashboard-tiles li svg {
  fill: #C5D800;
  margin: 0 auto 10px auto;
  height: 60px;
}
.dashboard-tiles li p {
  bottom: 20px;
  color: #fff;
  font-size: 14px;
  margin: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.dashboard-tiles li.disabled {
  opacity: 0.7;
}
.dashboard-tiles li.disabled:hover {
  background: rgba(135, 135, 135, 0.65);
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
}
.dashboard-tiles li.disabled svg {
  fill: #B8B8B8;
}
.dashboard-tiles.marketwatch-lite li svg {
  margin: 0 auto 10px auto;
  height: auto;
  width: 35px;
}
.dashboard-tiles.marketwatch-lite li.movement svg {
  width: 65px;
}

@media all and (min-width: 38em) {
  .dashboard-tiles li {
    margin: 0 3% 20px 0;
    width: 22.75%;
  }
  .dashboard-tiles li:nth-of-type(2n+2) {
    margin: 0 3% 20px 0;
  }
  .dashboard-tiles li:nth-of-type(4n+4), .dashboard-tiles li:last-of-type {
    margin: 0 0 20px 0;
  }
  .dashboard-tiles li svg {
    height: 70px;
  }
}
@media all and (min-width: 56em) {
  .dashboard-tiles li p {
    font-size: 16px;
  }
}
@media all and (min-width: 75em) {
  .dashboard-tiles li {
    padding: 45px 0 55px 0;
  }
  .dashboard-tiles li svg {
    height: 75px;
  }
}
.marketwatch-lite-container {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: row wrap;
  background: #4F4F4F;
  border-radius: 6px;
  color: #fff;
  padding: 15px 25px;
  text-align: center;
}
.marketwatch-lite-container h2 {
  color: #fff;
  font-size: 20px;
  margin: 0 0 20px 0;
}
.marketwatch-lite-container .btn {
  margin: 0 auto;
}

body.new-design .marketwatch-lite-container .btn:last-child {
  margin: 0 auto;
}

@media all and (min-width: 38em) {
  .marketwatch-lite-container {
    text-align: left;
  }
  .marketwatch-lite-container h2 {
    margin: 0;
  }
  .marketwatch-lite-container .btn {
    margin: 0;
  }

  body.new-design .marketwatch-lite-container .btn:last-child {
    margin: 0;
  }
}
.dashboard-widgets {
  list-style: none;
  margin: 0 0 40px 0;
}
.dashboard-widgets li {
  border-bottom: 1px solid #fff;
  color: #fff;
  margin: 0 auto 40px auto;
  text-align: center;
  width: 70%;
}
.dashboard-widgets li:last-of-type {
  border-bottom: none;
}
.dashboard-widgets li svg,
.dashboard-widgets li .temperature,
.dashboard-widgets li h3,
.dashboard-widgets li p {
  display: block;
}
.dashboard-widgets li svg {
  margin: 0 auto 10px auto;
  max-width: 65px;
  text-align: center;
  width: 30%;
}
.dashboard-widgets li .temperature {
  font-size: 54px;
  line-height: 1;
  margin-left: 15px;
}
.dashboard-widgets li h3 {
  font-size: 14px;
  margin: 0 0 10px 0;
  text-align: center;
}
.dashboard-widgets li p {
  font-size: 12px;
  margin: 0 0 40px 0;
}

@media all and (min-width: 38em) {
  .dashboard-widgets {
    border-left: 1px solid #fff;
    display: flex;
    flex-wrap: wrap;
  }
  .dashboard-widgets li {
    border-bottom: none;
    border-right: 1px solid #fff;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 30px;
    width: 33.333%;
  }
}
@media all and (min-width: 56em) {
  .dashboard-widgets li h3 {
    font-size: 16px;
  }
  .dashboard-widgets li p {
    font-size: 14px;
  }
}
.notifications-filters {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: row wrap;
  margin: 0 0 20px 0;
}
.notifications-filters select,
.notifications-filters a {
  font-size: 16px;
}
.notifications-filters select.notification-statuses,
.notifications-filters a.notification-statuses {
  margin: 0 0 10px 0;
  width: 100%;
}
.notifications-filters a {
  color: #4F4F4F;
}
.notifications-filters a:hover {
  color: #285238;
}
.notifications-filters select {
  background: #fff url("/shared-assets/Content/img/icons/icon_arrow_down_light.png") no-repeat right center;
  background-size: 24px;
  color: #4F4F4F;
}
.notifications-filters select.borderless-left-arrow {
  background: #fff url("/shared-assets/Content/img/icons/icon_arrow_down_light.png") no-repeat left 4px;
  background-size: 24px;
}
.notifications-filters .notifications-other-filters,
.notifications-filters .notification-statuses {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: row wrap;
}
.notifications-filters .notifications-other-filters {
  width: 100%;
}
.notifications-filters .notifications-other-filters a {
  margin: 0 20px 0 0;
}
.notifications-filters .notifications-other-filters a:last-of-type {
  margin: 0;
}
.notifications-filters .notification-statuses,
.notifications-filters .borderless-left-arrow {
  margin: 0;
  width: auto;
}

@media all and (min-width: 38em) {
  .notifications-filters .notification-types {
    margin: 0;
    width: 35%;
  }
  .notifications-filters .notifications-other-filters {
    width: 63%;
  }
}
@media all and (min-width: 56em) {
  .notifications-filters .notification-types {
    width: 25%;
  }
  .notifications-filters .notifications-other-filters {
    padding-left: 15px;
    width: 75%;
  }
}
@media all and (min-width: 56em) {
  .notifications-filters .notification-types {
    width: 25%;
  }
  .notifications-filters .notifications-other-filters {
    width: 75%;
  }
}
@media all and (min-width: 75em) {
  .notifications-filters .notification-types {
    width: 18%;
  }
  .notifications-filters .notifications-other-filters {
    width: 82%;
  }
}
.content-container .notifications-list {
  list-style: none;
  margin: 0;
}
.content-container .notifications-list li {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: row wrap;
  border: 1px solid #C8C8C8;
  border-radius: 6px;
  margin: 0 0 10px 0;
  padding: 25px 10px 25px 25px;
  position: relative;
}
.content-container .notifications-list li:before {
  background: #EEDD44;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}
.content-container .notifications-list li.market-notification:before {
  background: #EEDD44;
}
.content-container .notifications-list li.urgent-notification:before {
  background: #F47552;
}
.content-container .notifications-list li.marketwatch-notification:before {
  background: #B2BBDC;
}
.content-container .notifications-list li h1 {
  background: none;
  border: none;
  font-size: 13px;
  margin: 0 0 5px 0;
  text-align: left;
  text-transform: uppercase;
  padding: 0;
}
.content-container .notifications-list li p {
  font: 13px;
}
.content-container .notifications-list li .container-checkbox,
.content-container .notifications-list li .container-toggle-button {
  width: 10%;
}
.content-container .notifications-list li .container-message {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: row wrap;
  width: 70%;
}
.content-container .notifications-list li .container-message section {
  width: 100%;
}
.content-container .notifications-list li .container-message section:last-of-type {
  margin: 0;
}
.content-container .notifications-list li .toggle-content {
  border-top: 1px solid #BDBDBD;
  margin: 0 auto;
  width: 70%;
}
.content-container .notifications-list li .toggle-content .message-content {
  padding: 50px 0;
}
.content-container .notifications-list li .toggle-content .message-content .message-buttons {
  padding: 30px 0 0 0;
}
.content-container .notifications-list li .toggle-content .message-content .message-buttons a {
  color: #4F4F4F;
  display: block;
  margin: 0 0 10px 0;
  width: 100%;
}
.content-container .notifications-list li .toggle-content .message-content .message-buttons a:hover {
  color: #285238;
}
.content-container .notifications-list li .toggle-content .message-content .message-buttons a span {
  display: inline-block;
  height: 13px;
  position: relative;
  width: 25px;
}
.content-container .notifications-list li .toggle-content .message-content .message-buttons a span:before {
  font-size: 18px;
  left: 0;
  position: absolute;
  top: -2px;
}
.content-container .notifications-list li .toggle-content .toggle-close {
  color: #828282;
  font-size: 16px;
  padding: 20px 0 0 0;
  position: relative;
  text-align: center;
  width: 100%;
}
.content-container .notifications-list li .toggle-content .toggle-close span {
  color: #828282;
  display: block;
  margin: 0 0 3px 0;
}
.content-container .notifications-list li .toggle-content .toggle-close span:before {
  color: #828282;
  font-weight: bold;
}

@media all and (min-width: 38em) {
  .content-container .notifications-list li {
    padding: 25px 40px;
  }
  .content-container .notifications-list li .container-checkbox,
.content-container .notifications-list li .container-toggle-button {
    width: 10%;
  }
  .content-container .notifications-list li .container-toggle-button .u-icon-accordion:before {
    right: 0;
  }
  .content-container .notifications-list li .container-message {
    width: 80%;
  }
  .content-container .notifications-list li .container-message section {
    width: 60%;
  }
  .content-container .notifications-list li .container-message section:last-of-type {
    width: 35%;
  }
  .content-container .notifications-list li .toggle-content {
    width: 80%;
  }
  .content-container .notifications-list li .toggle-content .message-content .message-buttons {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-flow: row wrap;
  }
  .content-container .notifications-list li .toggle-content .message-content .message-buttons a {
    display: block;
    margin: 0 20px 10px 0;
    width: auto;
  }
}
@media all and (min-width: 56em) {
  .content-container .notifications-list li .container-checkbox,
.content-container .notifications-list li .container-toggle-button {
    width: 7%;
  }
  .content-container .notifications-list li .container-message {
    width: 86%;
  }
  .content-container .notifications-list li .container-message section {
    width: 70%;
  }
  .content-container .notifications-list li .container-message section:last-of-type {
    width: 25%;
  }
  .content-container .notifications-list li .toggle-content {
    width: 86%;
  }
}
@media all and (min-width: 75em) {
  .content-container .notifications-list li .container-checkbox,
.content-container .notifications-list li .container-toggle-button {
    width: 5%;
  }
  .content-container .notifications-list li .container-message {
    width: 90%;
  }
  .content-container .notifications-list li .container-message section {
    width: 80%;
  }
  .content-container .notifications-list li .container-message section:last-of-type {
    width: 15%;
  }
  .content-container .notifications-list li .toggle-content {
    width: 90%;
  }
}
@media all and (min-width: 115em) {
  .content-container .notifications-list li .container-checkbox,
.content-container .notifications-list li .container-toggle-button {
    width: 2%;
  }
  .content-container .notifications-list li .container-message {
    width: 96%;
  }
  .content-container .notifications-list li .container-message section {
    width: 88%;
  }
  .content-container .notifications-list li .container-message section:last-of-type {
    width: 10%;
  }
  .content-container .notifications-list li .toggle-content {
    width: 96%;
  }
}
.notifications-bulk-actions {
  background: #1A285E;
  bottom: -124px;
  height: 124px;
  overflow: hidden;
  padding: 30px 0;
  position: fixed;
  transition: all 0.7s ease;
  width: 100%;
  z-index: 3;
}
.notifications-bulk-actions.open {
  bottom: 0;
}
.notifications-bulk-actions section {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-flow: row wrap;
  margin: 0 auto;
  width: 80%;
}
.notifications-bulk-actions section a {
  color: #fff;
  font-size: 18px;
  margin: 0 0 10px 0;
}
.notifications-bulk-actions section a:first-of-type {
  margin: 0 30px 10px 0;
}
.notifications-bulk-actions section a:last-of-type {
  margin: 0;
}
.notifications-bulk-actions section a span {
  display: inline-block;
  height: 13px;
  position: relative;
  width: 25px;
}
.notifications-bulk-actions section a span:before {
  color: #fff;
  font-size: 18px;
  left: 0;
  position: absolute;
  top: -2px;
}

@media all and (min-width: 38em) {
  .notifications-bulk-actions {
    padding: 50px 0;
  }
  .notifications-bulk-actions section {
    justify-content: space-between;
    width: 70%;
  }
  .notifications-bulk-actions section a {
    margin: 0;
  }
  .notifications-bulk-actions section a:first-of-type {
    margin: 0;
  }
  .notifications-bulk-actions section a:last-of-type {
    margin: 0;
  }
}
#marketwatch-feedback {
  max-width: 750px;
  padding: 0;
}
#marketwatch-feedback h1 {
  background: transparent;
  color: #285238;
  font-size: 18px;
  font-weight: normal;
  margin: 0;
  padding: 25px;
  text-align: left;
}
#marketwatch-feedback h1:after {
  background: #F0F0F0;
  display: block;
  height: 1px;
  left: 0;
}
#marketwatch-feedback section {
  padding: 35px 25px;
  text-align: left;
}
#marketwatch-feedback section p {
  font-size: 18px;
}

@media all and (min-width: 38em) {
  #marketwatch-feedback section {
    padding: 35px 75px;
  }
}
.commodites-toggle-list {
  list-style: none;
  margin: 0 auto;
  max-width: 500px;
}
.commodites-toggle-list li {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: row wrap;
  background: #f2f2f2;
  border-radius: 4px;
  margin: 0;
  padding: 10px 20px;
}
.commodites-toggle-list li:nth-of-type(2n+2) {
  background: transparent;
}
.commodites-toggle-list li h2 {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}
.commodites-toggle-list li .subscribe-text {
  display: none;
}
.commodites-toggle-list li .subscribe-text span {
  font-size: 15px;
}
.commodites-toggle-list li .subscribe-text.Unsub span.Subscribed {
  display: none;
}
.commodites-toggle-list li .subscribe-text.Unsub span.Unsubscribed {
  display: block;
}
.commodites-toggle-list li .subscribe-text.Sub span.Subscribed {
  display: block;
}
.commodites-toggle-list li .subscribe-text.Sub span.Unsubscribed {
  display: none;
}

@media all and (min-width: 38em) {
  .commodites-toggle-list li {
    padding: 10px 20px;
  }
  .commodites-toggle-list li h2 {
    width: 60%;
  }
  .commodites-toggle-list li .subscribe-text {
    display: block;
    width: 20%;
  }
}
.system-messaging section {
  margin: 0 0 40px 0;
}