////////////////////////////
//this may be generic dashboard stuff
////////////////////////////
body.new-design {
    .dashboard {
        section {
            width: 100%;
        }

        h1,
        h2,
        h3,
        h4,
        .ui-widget,
        label,
        p {
            color: $white;
        }

        h1 {
            background: transparent;
            border-radius: 0;
            font-size: 30px;
            font-weight: 400;
            padding: 0;
        }

        label {
            padding-left: 0;
        }

        input,
        input[type="tel"],
        select {
            background: rgba(135, 135, 135, 0.75);
            border: 1px solid $silver2;
            border-radius: 3px;
            color: $white;
            font-size: 18px;  
            margin: 0;
            padding: 10px;

            &:hover {
                border-color: $white;
            }

            &:focus {
                border-color: $white;
                box-shadow: none;
                outline: none;
            }

            &::placeholder {
                color: rgba(255,255,255,0.8);
            }
        }

        select {
            background: rgba(135, 135, 135, 0.75) url('/shared-assets/Content/img/icons/icon_arrow_down_white.svg') no-repeat 98% center;
            background-size: 24px;
            color: $white;
            width: 100%;
          
            &.selected {
              color: $white;
            }
          }
    }

    .dashboard-welcome-container {
        padding: 40px 0;

        section {
            margin: 0 auto;
            width: auto;
        }

        h1,
        h2,
        p {
            text-align: center;
        }

        h1 {
            width: 100%;
        }

        h2 {
            font-weight: bold;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            position: relative;

            &:after {
                background: $white;
                bottom: 0;
                content: '';
                display: block;
                height: 1px;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        p {
            font: 9px;
        }
    }
}

@include bp(sm) {
    body.new-design {
        .dashboard-welcome-container {
            align-content: center;
            align-items: center;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-flow: row wrap;

            h1 {
                text-align: left;
                width: 60%;
            }

            section {
                width: 35%;
            }
        }
    }
}

@include bp(md) {
    body.new-design {
        .dashboard-welcome-container {
            h1 {
                width: 70%;
            }

            section {
                margin: 0;
                width: 25%;
            }
        }

        .dashboard {
            input,
            input[type="tel"],
            select {
                font-size: 20px; 
            }

            .two-column {
                align-items: flex-start;

                .left-column {
                    width: 70%;
                }

                .right-column {
                    margin-top: 27px;
                    width: 25%;
                }
            }
        }
    }
}
    
@include bp(lg) {
    body.new-design {
        .dashboard {
            position: relative;

            main.full-width {
                margin: 100px 5% 40px 10%;
                max-width: 85%;
            }
        }
    }
}

//dashboard-widget-right-column
body.new-design {
    .dashboard-widget-right-column {
        .widget-header {
            h1 {
                font-size: 18px;
            }
        }
    }
}

.dashboard-widget-right-column {
    background: rgba(135, 135, 135, 0.65);
    border-radius: 6px;

    .widget-header {
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-flow: row wrap;

        border-bottom: 1px solid $white;
        padding: 10px 20px;

        h1 {
            margin: 0;
            width: auto;
        }

        .widget-menu {
            padding: 5px 0 0 0;
            width: auto;
            
            svg {
                fill: $white;
            }
        }
    }

    .widget-content {
        padding: 20px;

        .btn {
            margin: 0;
            padding: 9px 20px 11px 20px;
            width: 100%;
        }
    }

    .notifications-list {
        list-style: none;
        margin: 0 0 40px 0;

        li {
            background: rgba($biscay, 0.2);
            color: $white;
            font-size: 10px;
            margin: 0;
            padding: 10px;

            &:nth-of-type(2n+2) {
                background: transparent;
            }

            a {
                color: $white;

                &:hover {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }

}

////////////////////////////
//dashboard
////////////////////////////

.market-watch-dashboard {
    background: url('../../Content/img/dashboard-bg-marketwatch-min.jpg') no-repeat center center fixed;
    background-size: cover;
    display: block;
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    width: 100%;

    &.strawberry {
        background: url('../../Content/img/dashboard-bg-marketwatch-strawberry-min.jpg') no-repeat center center fixed;
        background-size: cover;
    }

    &.blueberry {
        background: url('../../Content/img/dashboard-bg-marketwatch-blueberry-min.jpg') no-repeat center center fixed;
        background-size: cover;
    }

    &.peach {
        background: url('../../Content/img/dashboard-bg-marketwatch-peach-min.jpg') no-repeat center center fixed;
        background-size: cover;
    }
}

.select-commodity,
.marketwatch-lite-container {
    margin: 0 0 50px 0;
}

.select-dashboard {
    margin: 0 0 20px 0;
}

//dashboard tiles
.dashboard-tiles {
    align-content: center;
    display: flex;
    justify-content: center;

    flex-wrap: wrap;

    list-style: none;
    margin: 0;

    li {
        background: rgba(135, 135, 135, 0.65);
        border-radius: 6px;

        display: flex;
        flex-direction: column;

        margin: 0 4% 10px 0;
        padding: 25px 0 35px 0;
        position: relative;
        text-align: center;
        transition: all $base-duration $base-timing;
        width: 48%;

        &:nth-of-type(2n+2),
        &:last-of-type {
            margin: 0 0 10px 0;
        }

        &:hover {
            cursor: pointer;
            background: rgba(135, 135, 135, 0.95);
        }

        svg {
            fill: $rio-grande;
            margin: 0 auto 10px auto;
            height: 60px;
        }

        p {
            bottom: 20px;
            color: $white;
            font-size: 14px;
            margin: 0;
            position: absolute;
            text-align: center;
            width: 100%;
        }

        &.disabled {
            opacity: 0.7;

            &:hover {
                background: rgba(135, 135, 135, 0.65);
                cursor: not-allowed;
		        opacity: 0.7;
		        pointer-events: none;
            }

            svg {
                fill: $silver;
            }
        }
    }

    &.marketwatch-lite {
        li {
            svg {
                margin: 0 auto 10px auto;
                height: auto;
                width: 35px;
            }


            &.movement {
                svg {
                    width: 65px;
                }
            }
        }
    }
}

@include bp(sm) {
    .dashboard-tiles {
        li {
            margin: 0 3% 20px 0;
            width: 22.75%;
    
            &:nth-of-type(2n+2) {
                margin: 0 3% 20px 0;
            }

            &:nth-of-type(4n+4),
            &:last-of-type {
                margin: 0 0 20px 0;
            }

            svg {
                height: 70px;
            }
        }
    }
}

@include bp(md) {
    .dashboard-tiles {
        li {
            p {
                font-size: 16px;
            }
        }
    }
}

@include bp(lg) {
    .dashboard-tiles {
        li {
            padding: 45px 0 55px 0;

            svg {
                height: 75px;
            }
        }
    }
}

//dashboard marketwatch lite
.marketwatch-lite-container {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-flow: row wrap;

    background: $emperor;
    border-radius: 6px;
    color: $white;
    padding: 15px 25px;
    text-align: center;

    h2 {
        color: $white;
        font-size: 20px;
        margin: 0 0 20px 0;
    }

    .btn {
        margin: 0 auto;
    }
}

body.new-design .marketwatch-lite-container .btn:last-child {
    margin: 0 auto;
}

@include bp(sm) {
    .marketwatch-lite-container {
        text-align: left;

        h2 {
            margin: 0;
        }

        .btn {
            margin: 0;
        }
    }

    body.new-design .marketwatch-lite-container .btn:last-child {
        margin: 0;
    }
}

//dashboards widgets
.dashboard-widgets {
    list-style: none;
    margin: 0 0 40px 0;

    li {
        border-bottom: 1px solid $white;
        color: $white;
        margin: 0 auto 40px auto;
        text-align: center;
        width: 70%;

        &:last-of-type {
            border-bottom: none;
        }

        svg,
        .temperature,
        h3,
        p {
            display: block;
        }
        
        svg {
            margin: 0 auto 10px auto;
            max-width: 65px;
            text-align: center;
            width: 30%;
        }

        .temperature {
            font-size: 54px;
            line-height: 1;
            margin-left: 15px;
        }

        h3 {
            font-size: 14px;
            margin: 0 0 10px 0;
            text-align: center;
        }

        p {
            font-size: 12px;
            margin: 0 0 40px 0;
        }
    }
}

@include bp(sm) {
    .dashboard-widgets {
        border-left: 1px solid $white;
        display: flex;
        flex-wrap: wrap;
    
        li {
            border-bottom: none;
            border-right: 1px solid $white;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0 30px;
            width: 33.333%;
        }
    }
}

@include bp(md) {
    .dashboard-widgets {
        li {
            h3 {
                font-size: 16px;
            }
    
            p {
                font-size: 14px;
            }
        }
    }
}

////////////////////////////
//notifications
////////////////////////////

//notifications filter
.notifications-filters {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-flow: row wrap;

    margin: 0 0 20px 0;

    select,
    a {
        font-size: 16px;

        &.notification-statuses {
            margin: 0 0 10px 0;
            width: 100%;
        }
    }

    a {
        color: $emperor;

        &:hover {
            color: $plantation;
        }
    }

    select {
        background: $white url('/shared-assets/Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
        background-size: 24px;
        color: $emperor;
    
        &.borderless-left-arrow {
          background: $white url('/shared-assets/Content/img/icons/icon_arrow_down_light.png') no-repeat left 4px;
          background-size: 24px;
        }
    }

    .notifications-other-filters,
    .notification-statuses {
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-flow: row wrap;
    }

    .notifications-other-filters {
        width: 100%;

        a {
            margin: 0 20px 0 0;

            &:last-of-type {
                margin: 0;
            }
        }
    }

    .notification-statuses,
    .borderless-left-arrow {
        margin: 0;
        width: auto;
    }
}

@include bp(sm) {
    .notifications-filters {
        .notification-types {
            margin: 0;
            width: 35%;
        }

        .notifications-other-filters {
            width: 63%;
        }
    }
}

@include bp(md) {
    .notifications-filters {
        .notification-types {
            width: 25%;
        }

        .notifications-other-filters {
            padding-left: 15px;
            width: 75%;
        }
    }
}

@include bp(md) {
    .notifications-filters {
        .notification-types {
            width: 25%;
        }

        .notifications-other-filters {
            width: 75%;
        }
    }
}

@include bp(lg) {
    .notifications-filters {
        .notification-types {
            width: 18%;
        }

        .notifications-other-filters {
            width: 82%;
        }
    }
}

//notifications-list
.content-container {

    .notifications-list {
        list-style: none;
        margin: 0;

        li {
            align-content: center;
            align-items: center;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-flow: row wrap;

            border: 1px solid $silver2;
            border-radius: 6px;
            margin: 0 0 10px 0;
            padding: 25px 10px 25px 25px;
            position: relative;

            &:before {
                background: $starship;
                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 15px;
            }

            &.market-notification {
                &:before {
                    background: $starship;
                }
            }

            &.urgent-notification {
                &:before {
                    background: $jaffa;
                }
            }

            &.marketwatch-notification {
                &:before {
                    background: $pigeon-post;
                }
            }

            h1 {
                background: none;
                border: none;
                font-size: 13px;
                margin: 0 0 5px 0;
                text-align: left;
                text-transform: uppercase;
                padding: 0;
            }

            p {
                font: 13px;
            }

            .container-checkbox,
            .container-toggle-button {
                width: 10%;
            }

            .container-message {
                align-content: center;
                align-items: center;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                flex-flow: row wrap;
                
                width: 70%;

                section {
                    width: 100%;

                    &:last-of-type {
                        margin: 0;
                    }
                }
            }

            .toggle-content {
                border-top: 1px solid $silver3;
                margin: 0 auto;
                width: 70%;

                .message-content {
                    padding: 50px 0;

                    .message-buttons {
                        padding: 30px 0 0 0;
                        a {
                            color: $emperor;
                            display: block;
                            margin: 0 0 10px 0;
                            width: 100%;

                            &:hover {
                                color: $plantation;
                            }

                            span {
                                display: inline-block;
                                height: 13px;
                                position: relative;
                                width: 25px;

                                &:before {
                                    font-size: 18px;
                                    left: 0;
                                    position: absolute;
                                    top: -2px;
                                }
                            }
                        }
                    }
                }

                .toggle-close {
                    color: $grey;
                    font-size: 16px;
                    padding: 20px 0 0 0;
                    position: relative;
                    text-align: center;
                    width: 100%;

                    span {
                        color: $grey;
                        display: block;
                        margin: 0 0 3px 0;

                        &:before {
                            color: $grey;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

@include bp(sm) {
    .content-container {    
        .notifications-list {
            li {
                padding: 25px 40px;
        
                .container-checkbox,
                .container-toggle-button {
                    width: 10%;
                }

                .container-toggle-button {
                    .u-icon-accordion:before {
                        right: 0;
                    }
                }
        
                .container-message {
                    width: 80%;
        
                    section {
                        width: 60%;
        
                        &:last-of-type {
                            width: 35%;
                        }
                    }
                }
        
                .toggle-content {
                    width: 80%;
        
                    .message-content {    
                        .message-buttons {
                            align-content: center;
                            align-items: center;
                            display: flex;
                            justify-content: flex-start;
                            flex-direction: row;
                            flex-flow: row wrap;

                            a {
                                display: block;
                                margin: 0 20px 10px 0;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include bp(md) {
    .content-container {  
        .notifications-list {
            li {    
                .container-checkbox,
                .container-toggle-button {
                    width: 7%;
                }
        
                .container-message {
                    width: 86%;
        
                    section {
                        width: 70%;
        
                        &:last-of-type {
                            width: 25%;
                        }
                    }
                }
        
                .toggle-content {
                    width: 86%;
                }
            }
        }
    }
}

@include bp(lg) {
    .content-container {  
        .notifications-list {
            li {    
                .container-checkbox,
                .container-toggle-button {
                    width: 5%;
                }
        
                .container-message {
                    width: 90%;
        
                    section {
                        width: 80%;
        
                        &:last-of-type {
                            width: 15%;
                        }
                    }
                }
        
                .toggle-content {
                    width: 90%;
                }
            }
        }
    }
}

@include bp(xlg) {
    .content-container {  
        .notifications-list {
            li {    
                .container-checkbox,
                .container-toggle-button {
                    width: 2%;
                }

                .container-message {
                    width: 96%;
        
                    section {
                        width: 88%;
        
                        &:last-of-type {
                            width: 10%;
                        }
                    }
                }
        
                .toggle-content {
                    width: 96%;
                }
            }
        }
    }
}

//notifications-bulk-actions
.notifications-bulk-actions {
    background: $biscay;
    bottom: -124px;
    height: 124px;
    overflow: hidden;
    padding: 30px 0;
    position: fixed;
    transition: all $base-duration $base-timing;
    width: 100%;
    z-index: 3;

    &.open {
        bottom: 0;
    }

    section {
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-flow: row wrap;
        margin: 0 auto;
        width: 80%;

        a {
            color: $white;
            font-size: 18px;
            margin: 0 0 10px 0;

            &:first-of-type {
                margin: 0 30px 10px 0;
            }

            &:last-of-type {
                margin: 0;
            }

            span {
                display: inline-block;
                height: 13px;
                position: relative;
                width: 25px;

                &:before {
                    color: $white;
                    font-size: 18px;
                    left: 0;
                    position: absolute;
                    top: -2px;
                }
            }
        }
    }
}

@include bp(sm) {
    .notifications-bulk-actions {
        padding: 50px 0;
        
        section {
            justify-content: space-between;
            width: 70%;
    
            a {
                margin: 0;
    
                &:first-of-type {
                    margin: 0;
                }
    
                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
}

//feedback modal
#marketwatch-feedback {
    max-width: 750px;
    padding: 0;

    h1 {
        background: transparent;
        color: $plantation;
        font-size: 18px;
        font-weight: normal;
        margin: 0;
        padding: 25px;
        text-align: left;

        &:after {
            background: $gallery;
            display: block;
            height: 1px;
            left: 0;
        }
    }

    section {
        padding: 35px 25px;
        text-align: left;

        p {
            font-size: 18px;
        }
    }
}

@include bp(sm) {
    #marketwatch-feedback {
        section {
            padding: 35px 75px;
        }
    }
}

//admin
.commodites-toggle-list {
    list-style: none;
    margin: 0 auto;
    max-width: 500px;

    li {
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-flow: row wrap;

        background: $ada-grey-5;
        border-radius: 4px;
        margin: 0;
        padding: 10px 20px;

        &:nth-of-type(2n+2) {
            background: transparent;
        }

        h2 {
            font-size: 16px;
            font-weight: normal;
            margin: 0;
        }

        .subscribe-text {
            display: none;

            span {
                font-size: 15px;
            }

            &.Unsub {
                span.Subscribed {
                    display: none;
                }

                span.Unsubscribed {
                    display: block;
                }
            }

            &.Sub {
                span.Subscribed {
                    display: block;
                }

                span.Unsubscribed {
                    display: none;
                }
            }

        }
    }
}

@include bp(sm) {
    .commodites-toggle-list {
        li {
            padding: 10px 20px;

            h2 {
                width: 60%;
            }

            .subscribe-text {
                display: block;
                width: 20%;
            }
        }
    }
}

//system messaging
.system-messaging {    
    section {
        margin: 0 0 40px 0;
    }
}