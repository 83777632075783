//login page
body.new-design.marketwatch {
    main {
        &.login-page {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: flex-start;

            height: 100%;
            margin: 0;
            max-width: 1260px;
            width: 100%;

            .st0 {
                fill: $white;
            }
        }
    }
}



