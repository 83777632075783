//login page
body.new-design.marketwatch {
    color: $almost-black;

    h1,
    h2,
    h3,
    h4,
    .ui-widget,
    label,
    input, 
    select, 
    textarea {
        color: $almost-black;
    }

    main {
        &.login-page {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: flex-start;

            height: 100%;
            margin: 0;
            max-width: 1266px;
            width: 100%;

            .logo-fill {
                fill: $white;
            }
        }
    }
}

//overwrite universal sticky footer on desktop
@include bp(md) {
    body.new-design.marketwatch {
        .site-outer-wrapper {
            flex: inherit;
        }
    }
}

@include bp(md) {
    body.new-design.marketwatch {
        .site-outer-wrapper {
            margin-top: 60px;
        }

        main {
            &.login-page {
                border-radius: 6px;
                box-shadow: 0 0 12px rgba(0,0,0,0.35);
                margin: 0 auto;
                min-height: 709px;
                overflow: hidden;
                width: 90%;

                align-items: stretch;

                .logo-fill {
                    fill: $chambray;
                }
            }
        }
    }
}

//containers
body.new-design.marketwatch {
    .slogan-container {
        background: url('../../Content/img/login-bg.jpg') no-repeat center center;
        background-size: cover;
        display: none;
        height: 100%;
        padding: 65px;
        width: 63%;

        h1,
        h3,
        p {
            color: $white;
            max-width: 500px;
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        height: 100%;
        padding: 0 0 5px 0;
        width: 100%;
    }

    .logo-container {
        background: url('../../Content/img/login-bg.jpg') no-repeat center center;
        background-size: 200%;
        margin: 0 auto;
        padding: 28px 0;
        text-align: center;
        width: 100%;

        svg {
            width: 285px;
        }
    }

    .content-container {
        height: 100%;
        max-width: 400px;
        padding: 40px 15px 15px 15px;
        position: relative;
        width: 100%;
    }

    // .login-form {
    //     padding: 0 0 20px 0;
    //     margin: 0 auto;
    //     max-width: 325px;
    // }
}

@include bp(md) {
    body.new-design.marketwatch {
        .slogan-container {
            display: block;
        }

        .content-wrapper {
            padding: 0 0 15px 0;
            width: 37%;
        }

        .content-container {
            padding: 40px 30px 15px 30px;
        }    

        .logo-container {
            background: none;
    
            svg {
                width: 285px;
            }
        }
    }
}

//footer-container
body.new-design.marketwatch {
    .footer-container {
        font-size: 14px;
        padding: 0 15px;
        width: 100%;
    }
}

@include bp(md) {
    body.new-design.marketwatch {
        .footer-container {
            font-size: 16px;
        }
    }
}

//footer-links
.footer-links {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    line-height: 1;
    list-style: none;
    margin: 0 35px 10px 35px;

    li {
        padding: 0 10px;
        position: relative;

        &:after {
            background: $mine-shaft;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            width: 1px;
        }

        &:after {
            right: 0;
            top: 0;
        }

        &:first-of-type {
            padding: 0 10px 0 0;
        }

        &:nth-of-type(2) {
            padding: 0 0 0 10px;

            &:after {
                display: none;
            }
        }

        &:last-of-type {
            padding: 0 0 0 10px;

            &:after {
                display: none;
            }
        }
    }
}

@include bp(md) {
    .footer-links {
        margin: 0 5px 15px 5px;

        li {
            padding: 0 5px;

            &:first-of-type {
                padding: 0 5px 0 0;
            }

            &:nth-of-type(2) {
                padding: 0 5px;

                &::after {
                    display: block;
                }
            }

            &:last-of-type {
                padding: 0 0 0 5px;
            }
        }
    }
}

//footer-copyright
.footer-copyright {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    div {
        width: 100%;

        &:nth-of-type(2) {
            display: none;
        }
    }
}

@include bp(md) {
    .footer-copyright {
        div {
            width: auto;
    
            &:nth-of-type(2) {
                display: initial;
                padding: 0 5px;
            }
        }
    }
}

//company-list and user-list
body.new-design.marketwatch {
    input.company-select {
        border: 1px solid $chambray;
        border-bottom: none;
        border-radius: 4px 4px 0 0;
        margin: 0;
    }


    .company-list,
    .user-list {
        border: 1px solid $chambray;
        border-radius: 4px;

        ul.striped-list {
            li {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: center;
                justify-content: flex-start;

                min-height: 44px;
                padding: 0;

                &:hover {
                    background: $chambray;
                    
                    a {
                        color: $white;
                        font-weight: 600;
                    }
                }

                a {
                    color: $almost-black;
                    display: block;
                    font-weight: 400;
                    height: auto;
                    min-height: 100%;
                    padding: 0 15px;
                    width: 100%;
                }

                &.active {
                    background: $chambray;

                    a {
                        color: $white;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .company-list {
        border-radius: 0 0 4px 4px;
    }
}






