@media print {
    /////////////////////
    //General
    /////////////////////
    .print-no-display {
        display: none !important;
    }

    body {
        padding: 0;

        header#top-bar,
        .ad,
        .button-container,
        .button-container.section-top,
        .subsection-nav-wrapper,
        button,
        a.button,
        a.btn,
        form#crop_iq_reports,
        footer.site-footer {
            display: none !important;
        }
    }

    //toggle-content
    .toggle-content {
        display: block !important;
    }

    //body
    body { 
        &.logged-in {
            padding: 0 !important;
        }
    }

    .logged-in {
        main {
            margin: 0 auto !important;
            max-width: none;
            padding: 0;
            width: auto;

            &.print {
                margin: bsu(0.5) auto;
            }
        }
    }

        
    //grid
    .site-grid {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;
        align-items: flex-start;
        list-style: none;
        margin: 0;

        li {
            width: 23.5%;

            &:nth-child(3n+3) {
                margin: 0 2% bsu(1) 0;
            }

            &:nth-child(4n+4) {
                margin: 0 0 bsu(1) 0;
            }
        }
    }

    /////////////////////
    //columns
    /////////////////////
    //two columns
    .two-columns {
        margin-bottom: 0;
    
        // table {
        //     tr {
        //         border-top: none;

        //         th {
        //             background: $hub;
        //             color: $white;
        //             padding: bsu(0.5) bsu(0.75) bsu(0.5) bsu(0.75);
        //         }
        //     }
        // }
    }
        
    //wrappers
    .two-columns {
        align-items: top;
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        justify-content: center;

        .column {
            margin: 0 2% 0 0;
            width: 49%;

            &:nth-of-type(2n+2) {
                margin: 0;
            }
        }
    }
    
    //sidebar
    //wrappers
    .column.sidebar {
        margin: 0 3% 0 0;
        width: 67%;

        &:nth-of-type(2n+2) {
            margin-bottom: bsu(1);
            width: 30%;
        }
    }
    
    //maps
    //wrappers
    .two-column-map-info {
        margin: 0 3% 0 0;
        width: 30%;
    }

    .two-column-map-map {
        width: 67%;
    }

    //trhee columns
    .three-columns {
        display: flex;
        align-items: left;
        justify-content: left;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;
    
        align-content: flex-end;

        section {
            margin: 0 2% bsu(1) 0;
            width: 32%;

            &:nth-of-type(3n+3) {
                margin: 0 0 bsu(1) 0;
            }
        }
    }

    //6 columns
    .six-columns {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: top;
        justify-content: center;

        section {
            margin: 0 2% bsu(1) 0;
            width: 15%;

            &:nth-of-type(2n+2) {
                margin: 0 2% bsu(1) 0;
            }
            
            &:nth-of-type(6n+6) {
                margin: 0 0 bsu(1) 0;
            }
        }

        &.weather-conditions {
            page-break-after: always;

            section {
                height: 100px;
                margin: 0 2% 0 0;
                width: 15%;

                &:last-of-type {
                    height: 100px;
                    margin: 0;
                    width: 15%;
                }
            }
        }
    }

    /////////////////////
    //forms
    /////////////////////
    .form-flex {    
        p {
            padding: bsu(0.4) 0 bsu(0.55) 0;
        }
    }

    //2 column inputs
    .form-flex {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;

        align-content: flex-end;

        .form-item {
            display: inline-block;
            margin: 0 2% 0 0;
            width: 49%;

            &:nth-of-type(2n+2) {
                margin: 0 0 0 0;
            }
        }

        // button,
        // a.button,
        // a.btn {
        //     margin-top: 7px;
        // }
    }
  
    //3 column inputs
    .form-flex.three-column {
        display: flex;
        align-items: left;
        justify-content: left;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;

        align-content: flex-end;

        .form-item {
            display: inline-block;
            margin: 0 2% 0 0;
            width: 32%;

            &:nth-of-type(3n+3) {
                margin: 0 0 0 0;
            }
        }
    }
  
    //4 column inputs
    .form-flex.four-column {
        display: -webkit-flex;
        display: flex;
        align-items: left;
        justify-content: left;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;

        align-content: flex-end;

        .form-item {
            display: inline-block;
            margin: 0 2% 0 0;
            width: 23.5%;
      
            &:nth-of-type(2n+2) {
                margin: 0 2% 0 0;
            }
      
            &:nth-of-type(4n+4) {
                margin: 0 0 0 0;
            }
        }
    }
  
    //5 column inputs
    .form-flex.five-column {
        align-content: flex-end;
        display: -webkit-flex;
        display: flex;
        align-items: left;
        justify-content: left;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;

        .form-item {
            display: inline-block;
            margin: 0 2% 0 0;
            width: 18.4%;
      
            &:nth-of-type(2n+2) {
                margin: 0 2% 0 0;
            }
      
            &:nth-of-type(4n+4) {
                margin: 0 2% 0 0;
            }
      
            &:nth-of-type(5n+5) {
                margin: 0 0 0 0;
            }
        }
    }
    
    /////////////////////
    //tables
    /////////////////////
    table,
    table.non-responsive {
        display: table !important;
        margin: 0 0 20px 0 !important;

        thead {
            line-height: $heading-line-height;
            vertical-align: top;
            clip: none !important;
            height: auto !important;
            margin: 0;
            padding: 0;
            position: relative;
            width: auto !important;
        }

        tr {
            border: none !important;
            border-bottom: 1px solid $iron !important;
            display: table-row !important;
        }

        td,
        th {
            display: table-cell !important;
            padding: 10px !important;
            text-align: left !important;

            &:before {
                display: none;
            }
        }
    }

    tr {
        td {
            background: $concrete;

            &:nth-child(2n+2) {
                background: $concrete;
            }

            input,
            select {
                float: none;
                width: 100%;
            }
        }

        &:nth-child(2n+2) {
            td {
                background: $white;

                &:nth-child(2n+2) {
                    background: $white;
                }
            }
        }
    }
      
    // th {
    //     padding: bsu(0.5) bsu(0.5) bsu(0.5) bsu(0.5);
    // }
      
    // td {
    //     background: transparent;
    //     padding: bsu(0.25) bsu(0.5);
    // }

    /////////////////////
    //crop iq
    /////////////////////

    //application
    .checkbox-container,
    .two-column-right.weather {
        .styled-radio,
        .styled-checkbox {
            & + label:before {
                padding: 2px 0 0 2px;   
                top: -1px;
            }
        }
    }
    
    .two-column-right.weather {
        .styled-checkbox {
            & + label:before {
                padding: 0 0 0 2px;
                top: 2px;
            }
        }
    }

    #cropiq_application_information_all,
    #cropiq_application_information_farm,
    #cropiq_application_information_block {
        .time-date {
            label {
                margin: 0 2% 0 0;
                padding-top: 8px;
                text-align: right;
                width: 14%;
            }

            input {
                &.datepicker {
                    width: 46%;
                }

                &.hour,
                &.minutes {
                    width: 10%;
                }
            }

            select.am-pm {
                width: 12%;
            }

            p.hours {
                font-size: 12px;
                margin: 0;
                padding-top: 8px;
                width: 62%;
            }
        }        
    }

    table.information-blocks {
        td {
            &:nth-child(3),
            &:nth-child(4) {
                overflow: hidden;

                input,
                select {
                    float: left;
                    margin: 0 2% 0 0;
                    width: 28%;
                }

                select {
                    margin: 0;
                    width: 40%;
                }
            }
        }
    }
    
    table.favorites {
        th,
        td {
            width: 55%;

            &:first-of-type {
                width: 35%;
            }
    
            &:last-of-type {
                width: 10%;
            }
        }
    }

    h1.current-application-header {
        padding: 0;
    }

    .product-calculations {
        text-align: right;

        h3 {
            display: inline-block;
            margin: bsu(0.25) bsu(3) bsu(1) 0;

            &:last-of-type {
                margin: 0 0 bsu(1) 0;
            }
        }
    }

    #cropiq_application_products {
        #tabs {
            .form-flex {    
                .form-item {
                    width: 23.5%;

                    &:first-of-type,
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(6),
                    &:nth-of-type(7),
                    &:nth-of-type(8) {
                        width: 15%;
                    }
                }
            }
        }
    }

    .current-application {
        tr {
            border: none;
            margin: 0;

            td {
                background: $concrete;
                color: $ship-gray;
                overflow: visible;

                &:first-of-type {
                    display: table-cell;
                }

                &:nth-child(2) {
                    background: $concrete;
                    color: $ship-gray;
                }
            }

            &:nth-child(2n+2) {
                td {
                    background: $white;

                    &:nth-child(2) {
                        background: $white;
                    }
                }
            }
        }

        th,
        td {
            width: 14.75%;

            &:first-of-type,
            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(10) {
                text-align: center;
                width: 6%;
            }

            &:nth-of-type(2) {
                width: 10%;
            }

            &:nth-of-type(9) {
                width: 7%;
            }
        }
    }

    .two-column-right.weather {
        .form-item {
            &:last-of-type {
                border-bottom: 2px solid $hub;
                margin-bottom: bsu(1);
                padding-bottom: bsu(1);
            }
        }
    }

    //crop iq listing
    #records_filter {
        .form-flex.five-column {
            .form-item {
                margin: 0 2% 0 0;
                width: 18.4%;

                &:nth-child(2n+2) {
                    margin: 0 2% 0 0;
                }

                &:nth-child(3) {
                    margin: 0 2% 0 0;
                    width: 18.4%;

                    select {
                        width: 100%;
                    }
                }

                &:nth-child(4) {
                    margin: 0 2% 0 0;
                }
            }
        }
    }

    .report-summary {
        page-break-before: always;

        .row {
            page-break-after: always;
        }
    }
    
    //crop iq records
    .two-columns.records {
        .c-map-container {
            height: 100%;
        }
    }
    
    td.see-by-block {
        p {
            float: left;
            margin: 0 bsu(0.75) 0 0;
            width: auto;
        }

        .styled-checkbox,
        .styled-radio {
            & + label {
                margin: 0 bsu(0.5) 0 0;
                padding: 0 0 0 bsu(1.5);
                width: auto;

                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }

    .two-columns.records,
    .products-table,
    .products-table table.green-header,
    .ppe.three-columns,
    .weather-conditions.six-columns,
    .work-order-print {
        margin-bottom: 5px;

        h1 {
            font-size: 14px;
            margin-bottom: 5px !important;
            padding: 0 !important;
        }

        h4 {
            font-size: 12px;
        }

        th,
        p {
            font-size: 12px !important;
        }

        table,
        table.non-responsive,
        table.green-header {
            tr {
                th,
                td {
                    font-size: 12px !important;
                    padding: 2px 5px 2px 0 !important;
                }
            }
        }
    }

    .products-table {
        //page-break-before: always;

        table.green-header {
            thead {
                background: $hub;

                th {
                    &:nth-of-type(8),
                    &:nth-of-type(9) {
                        text-align: center;
                    }

                    &:last-of-type,
                    &:nth-last-of-type(2) {
                        display: none !important;
                    }
                }
            }

            tr {
                border-top: none;
                margin: 0;

                td {
                    &:nth-child(2n+2) {
                        background: $concrete;
                    }

                    &:nth-of-type(8),
                    &:nth-of-type(9) {
                        text-align: center;
                    }

                    &:last-of-type,
                    &:nth-last-of-type(2) {
                        display: none !important;
                    }
                }

                &:nth-child(2n+2) {
                    td {
                        background: transparent;

                        &:nth-child(2n+2) {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }

    .ppe.three-columns {
        border-bottom: none;
        margin-bottom: 5px;

        section {
            margin: 0;
            padding: 0 10px 0 0;
        }
    }

    //crop iq reports
    .report-preview,
    .report-print {
        table {
            thead {
                background: $hub;
                color: $white;
            }

            th,
            td {
                padding: 10px;
            }

            tr {
                margin: 0;

                th {
                    background: $hub;
                    color: $white;
                }

                td {
                    &:first-of-type {
                        background: $concrete;
                        color: $almost-black;
                    }
                }

                &:nth-of-type(2n+2) {
                    td {
                        &:first-of-type {
                            background: transparent;
                        }
                    }
                }
            }
        }

        .total-cost {
            overflow: hidden;
            text-align: right;

            h3 {
                float: right;
                padding: 0 0 0 bsu(0.5);
                width: 22.222%;

                span {
                    display: inline-block;
                    margin: 0;
                    width: 50%;
                }
            }
        }
    }

    .report-preview {
        table {
            th,
            td {
                width: 11.111%;
            }
        }
    }

    .report-print {
        table {
            border-bottom: 2px solid $iron !important;

            tr {
                th,
                td {
                    font-size: 8pt !important;
                    padding: 2px 5px 2px 0 !important;
                    width: 8%;

                    &:first-of-type {
                        height: auto;
                    }

                    &:nth-of-type(4),
                    &:nth-of-type(6) {
                        width: 10%;
                    }
                    
                    &:nth-of-type(5),
                    &:nth-of-type(7) {
                        width: 12%;
                    }
                }
            }
        }

        .total-cost {
            h3 {
                padding: 0 0 0 bsu(0.5);
                text-align: right;
                width: 100%;

                span {
                    margin: 0 30px 0 0;
                }
            }
        }
    }


    /////////////////////
    //dashboard
    /////////////////////
    .tools {
        margin: 0 8% 0 0;
        width: 46%;
    }

    .tools-inner-wrapper {
        .tool-container {
            a {
                padding: 0 10%;
                
                svg {
                    margin: bsu(1) 30% bsu(0.5) 30%;
                }

                h2 {
                    font-size: 18px;
                }
            }
        }
    }

    .widgets {
        width: 46%;
    }

    .step-slider-widget {
        .slide-inner-wrapper {
            h2 {
                font-size: 18px;
            }
        }
    }

    /////////////////////
    //season setup
    /////////////////////
    .season-site-info {
        max-width: 50%;
    }

    #season_setup-map-view {
        th {
            width: 21%;

            &:first-of-type {
                width: 32%;
            }

            &:last-of-type {
                width: 5%;
            }
        }
    }

    .choose-farm {

        h2 {
            &.check {
                margin: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }

    .planting-blocks,
    .planting-split-blocks {
        table-layout: auto;

        tr {
            &:nth-child(2n+2) {
                td {
                    &:first-of-type {
                        background: $white;
                    }
                }
            }
        }

        td {            
            &:first-of-type {
                background: $concrete;
                color: $ship-gray;
                padding-right: 40px;
                text-align: right;
                width: 12%;
            }

            &:nth-of-type(7),
            &:nth-of-type(8) {
                text-align: right;
                //width: 5%;
            }
        }
    }  
    
    .planting-blocks {
        margin: bsu(1) 0 0 0;
        
        tr {
            border-top: none;
            padding: 0;
        }
    }

    .planting-split-blocks {
        margin: 0 0 bsu(3) 0;
        width: 100%;

        &:before {
            display: block;
        }

        tr {
            &:before,
            &:after {
                display: none;
            }

            td {
                background: $white;
            }

            &:nth-child(2n+2) {
                td {
                    background: $concrete;
                }
            }
        }

        td {
            &:first-of-type {
                background: $white;
                position: relative;

                &:before {
                    background: $hub;
                    content: '';
                    display: block;
                    height: 2px;
                    left: 86px;
                    margin-top: 10px;
                    position: absolute;
                    top: inherit;
                    width: 20px;
                    z-index: 1;
                }
            }
        }
    }

    /////////////////////
    // Sites
    /////////////////////

    //site setup
    table.subsite.print {
        display: table;
    }

    #site_listing,
    #site_subsite_info {
        h2 {
            background: transparent;
            color: $ship-gray;
            padding: bsu(1) bsu(0.5) bsu(0.35) bsu(0.5);
        }

        table {
            &.original-site {
                td {
                    &:nth-child(2n+2) {
                        background: $iron;
                    }
                }
            }
        }

        //widths
        td, th {
            &:nth-of-type(1),
            &:nth-of-type(2) {
                width: 30%;
            }

            &:nth-of-type(3) {
                width: 25%;
            }

            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
                text-align: center;
                width: 5%;
            }
        }

        table.original-site {
            th {
                background: $hub;
                color: $white;
            }

            tr td {
                background: $iron;
            }
        }

        table.subsite {
            margin-left: 16%;
            width: 84%;

            th,
            td {
                &:nth-of-type(1) {
                    width: 16.75%;
                }

                &:nth-of-type(2) {
                    width: 35.75%;
                }

                &:nth-of-type(3) {
                    width: 47.5%;
                }
            }  
        }

        //lines
        table.subsite {
            &:before {
                background: $hub;
                bottom: 12px;
                content: '';
                display: block;
                height: 103%;
                left: -10%;
                position: absolute;
                width: 2px;
                z-index: 1;
            }

            tr {
                padding-bottom: 0;

                &:before {
                    left: -10%;
                    margin-top: 16px;
                    top: inherit;
                }

                &:after {
                    display: none;
                }

                td {
                    &:first-of-type {
                        background: $concrete;
                        color: $jumbo;
                    }
                }

                &:nth-child(2n+2) {
                    td {
                        &:first-of-type {
                            background: $white;
                        }
                    }
                }
            }
        }
    }

    .subsites-toggle {    
        .u-icon-accordion {
            &:before {
                right: 20px;
                top: 7px;
            }
        }
    }

    .draw-map {
        h2 {
            &:before {
                top: 0;
            }
        }
    }

    #site_setup {
        padding: bsu(0.75) 0 bsu(0.5) 0;
        
        .form-flex.three-column {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            flex-flow: row wrap;
                
            .form-item {
                display: inline-block;
                margin: 0 1% 0 0;
                width: 18%;

                &#site_name,
                &#site_address {
                    width: 30%;
                }

                &#site_state {
                    margin: 0 1% 0 0;
                }

                &#site_zip {
                    margin: 0 1% 0 0;
                    width: 14%;
                }
            }
        }
    }
    
    #site_subsite_info {
        table.subsite {
        
        //lines
            &:before {
                background: $hub;
                bottom: 22px;
                content: '';
                display: block;
                height: 102%;
                left: -15%;
                position: absolute;
                width: 2px;
                z-index: 1;
            }
            tr {
                padding-bottom: 0;
                &:before {
                    left: -15%;
                    margin-top: 16px;
                    top: inherit;
                }
                &:after {
                    display: none;
                }
                td {
                    &:first-of-type {
                        background: $concrete;
                        color: $jumbo;
                    }
                }
                &:nth-child(2n+2) {
                    td {
                        &:first-of-type {
                            background: $white;
                        }
                    }
                }
            }
        }

        //widths
        td, th {
            width: 19.333%;
            &:nth-of-type(2),
            &:nth-of-type(3) {
                width: 15%;
            }
            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(8) {
                text-align: center;
                width: 4%;
            }
        }
        table.subsite {
            margin-left: 19.5%;
            width: 80.5%;
            th,
            td {
                width: 24.5%;
                &:first-of-type {
                    width: 38%;
                }
                &:nth-of-type(4),
                &:nth-of-type(5),
                &:nth-of-type(6) {
                    text-align: center;
                    width: 5%;
                }
            }
        }
    }

    //base
    main {
        figure {
            padding: $large-spacing $xlarge-spacing 0 $xlarge-spacing;
        }
        
        .not-logged-in-top {
            padding: $small-spacing $xlarge-spacing 0 $xlarge-spacing;
        }
    }

    [data-view="Dashboard"] {
        main {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: top;
            justify-content: center;
        }
    }

    main.dashboard {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: top;
        justify-content: center;
    }


    /////////////////////
    //lab
    /////////////////////

    //lab results
    //sm breakpoint
    #lab_results {
        .form-item {
            width: 49%;

            &.start-date,
            &.end-date {
                margin: 0 2% 0 0;
                width: 49%;
            }

            &.end-date {
                margin: 0;
            }

            button {
                margin-top: 24px;
            }
        }
    }

    .results-table {
        .report-details {
            margin: 0 bsu(0.25);

            ul {
                display: flex;
                justify-content: center;
                flex-direction: row;
                flex-wrap: wrap;
                flex-flow: row wrap;
            
                align-content: flex-start;
                vertical-align: top;
                list-style: none;
                margin: 0;

                list-style: none;
                margin: 0;

                li {
                    margin-bottom: 2px;
                    width: 50%;
                }
            }
        }
    }

    #lab_results {
        .form-item {
            width: 23.5%;
    
            &.start-date,
            &.end-date {
                margin: 0 2% 0 0;
                width: 23.5%;
            }

            &.end-date,
            &:nth-child(2n+2) {
                margin: 0 2% 0 0;
            }

            &:nth-child(4n+4) {
                margin: 0;
            }
        }
    }

    .results-table {
        h1 {
            &:after {
                display: none;
            }
        }

        .row {
            margin-bottom: 0;

            tr {
                background: transparent;
            }

            &:nth-child(2n+2) {
                tr {
                    background: $concrete;
                }
            }
        }

        table.results-table-header,
        table.results-row {
            border: none;

            th,
            td {
                width: 15%;

                &:first-of-type {
                    padding-left: bsu(2);
                    width: 20%;
                }

                &:last-of-type {
                    text-align: center;
                    width: 5%;
                }
            }
        }

        table.results-row {
            td {
                background: transparent;

                &:first-of-type {
                    background: transparent;
                    color: $ship-gray;
                }
            }
        }

        .report-details {
            box-shadow: none;
            padding: 1px 1px 0 1px;

            ul {
                list-style: none;
                margin: 0;
                
                li {
                    margin-bottom: 1px;
                    width: 25%;
                }
            }
        }
    }

    /////////////////////
    //scouting
    /////////////////////
    
    //create new

    #scouting_create_new {
        .form-flex {
            &.two-columns {
                max-width: 40%;
            }
        }

        table.create-scouting-report-table {
            tr,
            thead {
                td,
                th {
                    vertical-align: top;
                    width: auto;
                    //width: 21%;
    
                    &:nth-of-type(2) {
                        width: 35%;
                    }
                    
                    &:nth-last-of-type(2) {
                        width: 12%;
                    }
    
                    &:first-of-type {
                        width: 8%;
                    }
    
                    &:last-of-type {
                        text-align: center;
                        width: 5%;
                    }
    
                    input,
                    textarea,
                    p {
                        float: none;
                        width: 100%;
                    }
    
                    input,
                    select {
                        margin: bsu(0.25) 0 0 0;
                        width: 100%;
                    }
                }
            }
    
            tr {
                padding: 0;
            }
        }
    }

    .two-columns.create-report,
    .two-columns.view-report {
        .column {
            width: 40%;

            &:nth-of-type(2n+2) {
                width: 58%;
            }
        }
    }

    // table.create-scouting-report-table {
    //     tr,
    //     thead {
    //         td,
    //         th {
    //             vertical-align: top;
    //             width: 21%;

    //             &:first-of-type,
    //             &:last-of-type {
    //                 width: 8%;
    //             }

    //             input,
    //             textarea,
    //             p {
    //                 float: none;
    //                 width: 100%;
    //             }

    //             input {
    //                 margin: bsu(0.25) 0 0 0;
    //             }
    //         }
    //     }

    //     tr {
    //         padding: 0;
    //     }
    // }

    table.create-scouting-report-table {
        tr,
        thead {
            td,
            th {
                vertical-align: top;
                width: auto;
                //width: 21%;

                &:nth-of-type(2) {
                    width: 30% !important;
                }
                
                // &:nth-last-of-type(2) {
                //     width: 12%;
                // }

                &:first-of-type {
                    width: 8% !important;
                }

                &:last-of-type {
                    text-align: center;
                    width: 15% !important;
                }

                input,
                textarea,
                p {
                    float: none;
                    width: 100%;
                }

                input,
                select {
                    margin: bsu(0.25) 0 0 0;
                    width: 100%;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    [data-view="scouting-view-reports"] {
        h1 {
            font-size: 14px;
            margin: 0 0 5px 0 !important;
            padding: 0 !important;

            &:after {
                display: none;
            }
        }

        textarea {
            margin-bottom: 0 !important;
        }

        .two-columns.view-report {
            margin-bottom: 0 !important;

            .column {
                margin-bottom: 0 !important;
            }

            table {
                tr {
                    th,
                    td,
                    p {
                        font-size: 12px !important;
                    }

                    th,
                    td {
                        padding: 2px 5px 2px 0 !important;
                    }
                }
            }
        }

        table.green-header.create-scouting-report-table {
            tr {
                th,
                td {
                    font-size: 12px !important;
                    margin: 0 !important;
                    padding: 2px 5px 2px 0 !important;
                    width: auto !important;

                    p {
                        font-size: 12px !important;
                        margin: 0 0 5px 0 !important;
                        padding: 0 !important;
                        width: 100%;
                    }

                    &:first-of-type {
                        width: 8% !important;
                    }
                    
                    &:last-of-type {
                        display: none !important;
                    }
                }
            }
        }
    }

    ///report records
    .scouting-records {
        tr {
            padding: 0;

            th {
                background: $hub;
                color: $white;
            }

            th,
            td {
                padding: bsu(0.5);
                width: auto;

                &:nth-of-type(3n+3) {
                    width: 30%;
                }
                    
                &:nth-of-type(4n+4) {
                    text-align: center;
                }

                &:last-of-type {
                    text-align: center;
                    width: 10%;
                }
            }
        }

        .styled-checkbox + label, 
        .styled-radio + label {
            margin: 0;

            &:before {
                left: 50%;
                margin: -10px 0 0 -10px;
            }
        }

        .email-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            flex-flow: row wrap;
            align-content: flex-end;
    
            margin-left: 50%;
            width: 50%;
        
            label {
                margin: 0 2% 0 0;
                width: 20%;
            }
        
            input {
                margin: 0 2% 0 0;
                width: 40%;
            }
        
            button {
                width: 36%;
            }
        }
    }

    /////////////////////
    //Input Costs
    /////////////////////
    .per {
        p {
            margin-top: 29px;
            padding: 0;
            width: 100%;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    .input-cost-report {
        margin-top: bsu(1);

        .row {
            p {
                width: 63%;
            }
        }
    }
    
    //cost-input-details
    .cost-input-details.toggle-button {
        width: 2%;
    }
    

    #input_costs {
        .five-column {
            .form-item {
                margin: 0 2% 0 0;
                width: 22%;

                &:nth-of-type(2n+2) {
                    margin: 0 2% 0 0;
                }

                &:nth-of-type(4) {
                    width: 4%;
                }

                &:last-of-type {
                    margin: 0;
                }
            }
        }

        button,
        .button {
            margin-top: 23px;
        }
    }

    .input-cost-details {
        table {
            thead {
                border-bottom: 2px solid $white;
                clip: 0;
                font-weight: 600;
                height: auto;
                margin: 0;
                position: relative;
                width: auto;

                th {
                    width: 25%;

                    &:nth-of-type(2) {
                        width: 20%;
                    }

                    &:last-of-type {
                        width: 30%;
                    }
                }
            }
        
            tr {
                border: none;
                display: table-row;
        
                td, th {
                    display: table-cell;
                    text-align: left;
                }
                
                th {
                    padding: bsu(0.25) bsu(0.5) bsu(0.5) bsu(0.5);
                }
            }
        
            td {
                background: transparent;
                border-radius: 0;
                padding: bsu(0.25) bsu(0.5);
            
                &:before {
                    display: none;
                }
            }
        }
    }


    /////////////////////
    //Label Search
    /////////////////////
    .search-results,
    .search-reults {
        .row {
            margin-bottom: 0;

            &:nth-child(2n+2) {
                tr {
                    td {
                        background: transparent;
                    }
                }
            }
        }

        table.search-reults-table,
        table.search-results-table {    
            th,
            td {
                width: auto;

                &:first-of-type {
                    padding-left: bsu(1.75);
                }

                &:last-of-type {
                    width: 40%;
                }
            }

            td {
                padding: 10px bsu(0.5);

                &:first-of-type {
                    padding: 10px bsu(0.5) 10px bsu(1.75);
                }
            }
        }
    }

    .view-labels.toggle-button {
        background: transparent;
        color: $ship-gray;
        left: 0;
        padding: 0;
        position: absolute;
        top: 5px;

        &:hover {
            background: transparent;
            color: $ship-gray; 
        }

        .u-icon-accordion {
            &:before {
                color: $ship-gray;
                left: 10px;
                top: 8px;
            }
        }

        &.open {
            .u-icon-accordion {
                &:before {
                    color: $pistachio;
                }
            }
        }

        label {
            visibility: hidden !important;
            transform: scale(0) !important;
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }

    .labels {
        margin: 0 bsu(0.25);
        padding: bsu(1) bsu(2);
    }

    //food safety log print

    .log-info.form-flex.two-column-mobile section p,
    .log-info section p {
        margin-bottom: 5px !important;
    }

    .log-header-field {
        width: 20%;
    }

    table.log-question-table {
        tr {
            td {
                width: 50%;
    
                &:nth-child(2n+2) {
                    width: 50%;
                }
            }
        }
    }

    table.log-checkbox-table {
        margin: 0 0 10px 0 !important;

        th, 
        td {
            text-align: center !important;
            width: 10%;
            
            &:first-of-type {
                text-align: left !important;
                width: 80%;
            }
        }
    }

    .e-sign {
        display: inherit;
        overflow: hidden;

        section {
            float: left;
            margin: bsu(2) 4% 0 0 !important;
            width: 48% !important;

            &:nth-of-type(2n+2),
            &:last-of-type {
                margin: bsu(2) 0 0 0 !important;
            }
        }
    }
}


