body.new-design.marketwatch {
    h3 {
        font-size: 18px;
    }
    
    a {
        color: $chambray;
    }

    .error {
        color: $red-orange;

        a {
            color: $red-orange;
            text-decoration: underline;
        }
    }
}