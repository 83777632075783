////////////////////////////////////////////////////////////////////
// new hub colors - 05/22
////////////////////////////////////////////////////////////////////
$marigold: #e29031;
$marigold-hover: #b57327;

$ship-gray: #404041;
$concrete: #f2f2f2;

$hub: #bfcf26;
$hub-hover: #acb823;

$pistachio: #9aab00;
$pistachio-hover: #7b8900;

$almost-black: #212626;
$silver3: #BDBDBD;

//Alert Tones
$approved: #006600;
$approved-bg: #5fcc5f;

$pending: #ffbf3d;
$starship: #EEDD44;

$not-submitted: #b10009;
$not-submitted-bg: #e8503e;

//Blue information
$ada-blue-1: #466db2;
$ada-blue-2: #55c3fc;

////////////////////////////////////////////////////////////////////
// new design colors 
////////////////////////////////////////////////////////////////////
//Highland Light Green
$rio-grande: #C5D800;
$bitter-lemon: #bbcc00;
$bitter-lemon-hover: #e6f074;
$tusk: #F1F4CB;
$light-green-gradient: linear-gradient(rgba(209,228,7,1.0), rgba(187, 204, 0,1));

//Highland Navy
$blue-zodiac: #0F1A41;
$biscay: #1A285E;
//$chambray: #3753c1;
$chambray: #3A4C93;
$link-water: #E1E5F5;
$blue-gradient: linear-gradient(rgba(58,76,147,1.0), rgba(27,54,93,1.0));

$san-marino: #5469B8;
$pigeon-post: #B2BBDC;

//Highland Teal
$sea-nymph: #7CA298;
$sea-nymph-hover: #97C2B7;

$shadow-green: #97C2B7;
$swans-down: #DBEDE8;

$cutty-sark: #58776f;
$cutty-shark-dark: #46615b;

$teal-gradient: linear-gradient(rgba(151,194,183,1.0), rgba(124,162,152,1.0));

$aqua-island: #AEDED1;

//Highland Mid Green
$plantation: #285238;
$killarney: #3C6A4E;
$midgreen-bright: #1d723e;
$skeptic: #CCE7D6;
$mid-green-gradient: linear-gradient(rgba(60,106,78,1.0), rgba(40,82,56,1.0));

//Highland Graphite
$graphite-primary: #54565b;
$graphite-hover: #76777b;
$graphite-gradient: linear-gradient(rgba(118,119,123,1.0), rgba(84,86,91,1.0));


//random greens
$viridan-green: #619575;
$surf: #AED8BE;
$palm-green: #06230B;
$dark-fern: #0B3E13;
$surf-crest: #D7E8DA;
$dark-green-gradient: linear-gradient(rgba(15,82,27,1.0), rgba(6,35,11,1.0));
$aqua-forest: #68A772;

//Accent Colors
$pacific-blue: #0AA9B4;
$turquoise: #49D9D0;
//$starship: #EEDD44;

//Quality Control 
$cape-cod: #394945;
$key-lime-pie: #bed130;
$spring-leaves: #58776f;
//$spring-leaves: #5A826A;



//Highland Red
$jaffa: #F47552;
$apricot: #F4997F;
$fair-pink: #FFEDE8;
$red-gradient: linear-gradient(rgba(244,117,82,1.0), rgba(175,41,4,1.0));

$wax-flower: #FFC4B4;
$watusi: #FFD9CE;




//Utility greys
$silver-chalice: #A8A8A8;
$silver: #B8B8B8;
$silver2: #C8C8C8;
$alto: #D8D8D8;
$mercury: #e5e5e5;
$gallery: #F0F0F0;
$new-grey: #e8ecef;
$porcelain: #E5ECEA;

//ADA greys
$ada-grey-2: #aaaaaa;
$ada-grey-3: #c6c6c6;
$ada-grey-4: #d7d7d7;
$ada-grey-5: #f2f2f2;
$ada-grey-6: #fafafa;


//Text greys 
$mine-shaft: #333333;
$emperor: #4F4F4F;
$grey: #828282;
//$silver3: #BDBDBD;




//status colors
//$approved: #006600;
//$pending: #ffbf3d;
//$not-submitted: #b10009;


////////////////////////////////////////////////////////////////////
// end new design colors 
////////////////////////////////////////////////////////////////////

// Black -> White
$black: #111;
//$almost-black: #212626;
//$ship-gray: #404041;
$scarpa-flow: #58585a;
$jumbo: #818285;
//$gray-chateau: #9BA0A5;
$gray-chateau: #a3a9ae;
$iron: #dddfe1;
$black-haze: #e6e7e7;
//$concrete: #f3f3f3;
//changed concrete color for ada compliance
//$concrete: #f2f2f2;
$alabaster: #f8f8f8;
$white: #fff;

// Colors
$highland: #618e55;
//$pistachio: #9aab00;
$dark-pistachio: #5d7133;
// $hub: #bfcf26;
// $hub-hover: #7b8900;
$hub-light: #dce19d;
$hub-lightest: #eceecb;
$hub-lightest: #f2f2da;
$off-white: #fcfcf8;
$off-white-dark: #f7f7e9;
$link-green: #65a322;

$blue: #69a6a5;
$light-blue: #7cbfbe;

$yellow: #ffd800;
// $marigold: #e29031;
// $marigold-hover: #b57327;
$orange: #ffa720;
$red-orange: #b10009;

$pink: #F012BE;
$purple: #6b349d;
$maroon: #85144B;
$midnight: #001F3F;
$aqua: #7FDBFF;
$ocean-green: #3D9970;
$green-apple: #2ECC40;
$lime: #01FF70;

//block colors
$red: #fa2629;
$dark-red: #931f1f;
$teal: #3cbc95;
$light-teal: #94c1b5;
$light-green: #d2df7c;
$green: #248001;
$brown: #563b12;
$dark-green: #174e02;
$dark-blue: #0e0374;


////////////////////////////////////////////////////////////////////
// new colors
////////////////////////////////////////////////////////////////////
$blue-green: #5d8076;


// Font Colors
$base-font-color: $almost-black;
$action-color: $hub;

// Border
$base-border-color: $ada-grey-3;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: $white;
$secondary-background-color: tint($base-border-color, 75%);

// System Vars
$success-color: $ocean-green;
$warning-color: $yellow;
$danger-color: $red-orange;



// Color map for functions

$color-map: (
    black: $black,
    almost-black: $almost-black,
    ship-gray: $ship-gray,
    scarpa-flow: $scarpa-flow,
    jumbo: $jumbo,
    gray-chateau: $gray-chateau,
    iron: $iron,
    black-haze: $black-haze,
    concrete: $concrete,
    alabaster: $alabaster,
    white: $white,
    highland: $highland,
    pistachio: $pistachio,
    hub: $hub,
    link-green: $link-green,
    midnight: $midnight,
    blue: $blue,
    aqua: $aqua,
    teal: $teal,
    ocean-green: $ocean-green,
    green-apple: $green-apple,
    lime: $lime,
    yellow: $yellow,
    marigold: $marigold,
    orange: $orange,
    red-orange: $red-orange,
    pink: $pink,
    purple: $purple,
    maroon: $maroon,
    sliver3: $silver3
);
